import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faBoxes,
  faUserCheck,
  faEnvelope,
  faFileInvoice,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

const Pannello = () => {
  return (
    <div className="flex flex-col items-center p-4">
      <h1 className="text-2xl font-bold mb-6 mt-20">Pannello di Controllo</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <Link
          to="/aggiungiarticolo"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Aggiungi Articolo
        </Link>

        <Link
          to="/inventario"
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faBoxes} className="mr-2" />
          Articoli
        </Link>

        <Link
          to="/vedicandidature"
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faUserCheck} className="mr-2" />
          Vedi Candidature
        </Link>

        <Link
          to="/vedimessaggi"
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          Vedi Messaggi
        </Link>

        <Link
          to="/admin/PrezziBloccatiOro"
          className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
          Pannello Valutazioni
        </Link>

        <Link
          to="/admin/CreazioneUtenti"
          className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
          Creazione Utenti
        </Link>

        <Link
          to="/admin/Utenti"
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faUsers} className="mr-2" />
          Gestione Utenti
        </Link>
      </div>
    </div>
  );
};

export default Pannello;
