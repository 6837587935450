// EditContext.js
import React, { createContext, useState } from 'react';

export const EditContext = createContext();

export const EditProvider = ({ children }) => {
    const [editingKey, setEditingKey] = useState(null);

    return (
        <EditContext.Provider value={{ editingKey, setEditingKey }}>
            {children}
        </EditContext.Provider>
    );
};