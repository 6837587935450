import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCartShopping,
  faCoins,
  faGem,
  faBalanceScale,
  faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';
import TextLabel from '../../editLabelComponents/textLabel';

import ValutazioneOro from './ValutazioneOro';
import ValutazioneArgento from './ValutazioneArgento';

const OurWork = () => {
  return (
    <section id="vendi" className="relative">
      <div className="relative py-9">
        <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
          <div className="text-center  ">
            <TextLabel
              keyProp="SERVIZI_TITLE"
              tagType="h2"
              customClass="text-md font-bold text-[#0d3960] text-center md:text-2xl mb-4"
   
            />
          </div>

          <div className="group relative grid grid-cols-1 md:grid-cols-3 gap-4 bg-transparent border border-yellow-500 rounded-lg p-4">
            <div className="flex flex-col justify-center items-center text-center space-y-4">
              <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                <FontAwesomeIcon
                  icon={faBalanceScale}
                  className="w-8 h-8 text-white"
                />
              </div>
              <div className="space-y-2">
                <TextLabel
                  keyProp="SERVIZI_A_TITLE"
                  tagType="h5"
                  customClass="text-xl font-semibold text-[#0d3960] transition"
                />
                <TextLabel
                  keyProp="SERVIZI_A_PARAGRAFO"
                  tagType="p"
                  customClass="text-gray-400"
                />
                <div className='inline-block text-center rounded-lg border border-yellow-500 px-2'>
                <a
                  href="/sedi"
                  className="bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300"
                >
                  Scopri le nostre sedi
                </a>
                </div>
              </div>
            </div>
            <div className="p-4 flex flex-col items-center">
              <ValutazioneOro className="h-full" />
            </div>
            <div className="rounded-lg p-4 flex flex-col items-center">
              <ValutazioneArgento className="h-full" />
            </div>
          </div>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="group relative bg-transparent border border-yellow-500 rounded-lg p-4">
              <div className="flex flex-col justify-center items-center text-center space-y-4">
                <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    className="w-8 h-8 text-white"
                  />
                </div>
                <div className="space-y-2">
                  <TextLabel
                    keyProp="SERVIZI_B_TITLE"
                    tagType="h5"
                    customClass="text-xl font-semibold text-[#0d3960] transition"
                  />
                  <TextLabel
                    keyProp="SERVIZI_B_PARAGRAFO"
                    tagType="p"
                    customClass="text-gray-400"
                  />
                </div>
              </div>
            </div>
            <div className="group relative bg-transparent border border-yellow-500 rounded-lg p-4">
              <div className="flex flex-col justify-center items-center text-center space-y-4">
                <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                  <FontAwesomeIcon
                    icon={faCoins}
                    className="w-8 h-8 text-white"
                  />
                </div>
                <div className="space-y-2">
                  <TextLabel
                    keyProp="SERVIZI_C_TITLE"
                    tagType="h5"
                    customClass="text-xl font-semibold text-[#0d3960] transition"
                  />
                  <TextLabel
                    keyProp="SERVIZI_C_PARAGRAFO"
                    tagType="p"
                    customClass="text-gray-400"
                  />
                </div>
              </div>
            </div>
            <div className="group relative bg-transparent border border-yellow-500 rounded-lg p-4">
              <div className="flex flex-col justify-center items-center text-center space-y-4">
                <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                  <FontAwesomeIcon
                    icon={faGem}
                    className="w-8 h-8 text-white"
                  />
                </div>
                <div className="space-y-2">
                  <TextLabel
                    keyProp="SERVIZI_D_TITLE"
                    tagType="h5"
                    customClass="text-xl font-semibold text-[#0d3960] transition"
                  />
                  <TextLabel
                    keyProp="SERVIZI_D_PARAGRAFO"
                    tagType="p"
                    customClass="text-gray-400"
                  />
                </div>
              </div>
            </div>
            <div className="group relative bg-transparent border border-yellow-500 rounded-lg p-4">
              <div className="flex flex-col justify-center items-center text-center space-y-4">
                <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
                  <FontAwesomeIcon
                    icon={faShieldHalved}
                    className="w-8 h-8 text-white"
                  />
                </div>
                <div className="space-y-2">
                  <TextLabel
                    keyProp="SERVIZI_E_TITLE"
                    tagType="h5"
                    customClass="text-xl font-semibold text-[#0d3960] transition"
                  />
                  <TextLabel
                    keyProp="SERVIZI_E_PARAGRAFO"
                    tagType="p"
                    customClass="text-gray-400"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurWork;
