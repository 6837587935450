import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, update, remove, push } from 'firebase/database';
import Modal from 'react-modal';


// Setting the app element for accessibility purposes
Modal.setAppElement('#root');

const AdminPage = () => {
  const [goldData, setGoldData] = useState([]);
  const [silverData, setSilverData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [storeName, setStoreName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const db = getDatabase();
    
    const goldDataRef = ref(db, 'OroBloccato');
    const silverDataRef = ref(db, 'ArgentoBloccato');
    
    const unsubscribeGold = onValue(goldDataRef, (snapshot) => {
      const data = snapshot.val();
      const formattedData = data ? Object.keys(data).map(key => ({
        id: key,
        ...data[key],
      })) : [];
      setGoldData(formattedData);
    });
    
    const unsubscribeSilver = onValue(silverDataRef, (snapshot) => {
      const data = snapshot.val();
      const formattedData = data ? Object.keys(data).map(key => ({
        id: key,
        ...data[key],
      })) : [];
      setSilverData(formattedData);
    });

    return () => {
      unsubscribeGold();
      unsubscribeSilver();
    };
  }, []);

  const isDataValid = (timestamp) => {
    const twelveHours = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
    return (Date.now() - timestamp) < twelveHours;
  };

  const handlePresoInLavorazione = (item, type) => {
    // Disabilita il pulsante per evitare doppie chiamate
    if (selectedItem) return;

    setSelectedItem({ ...item, type });
    setShowModal(true);
  };

  const handleSave = () => {
    if (selectedItem) {
      const db = getDatabase();
      const itemRef = ref(db, `${selectedItem.type}/${selectedItem.id}`);
  
      update(itemRef, {
        status: 'in lavorazione',
        storeName: storeName
      });
  
      // Update local state
      const updatedData = selectedItem.type === 'OroBloccato' ? [...goldData] : [...silverData];
      const index = updatedData.findIndex(item => item.id === selectedItem.id);
      if (index !== -1) {
        updatedData[index] = {
          ...updatedData[index],
          status: 'in lavorazione',
          storeName: storeName
        };
  
        const updateState = selectedItem.type === 'OroBloccato' ? setGoldData : setSilverData;
        updateState(updatedData);
      }
  
      setShowModal(false);
      setStoreName('');
      setSelectedItem(null);
    }
  };
  

  const handleDelete = (id, type) => {
    const db = getDatabase();
    const itemRef = ref(db, `${type}/${id}`);
    remove(itemRef);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const filteredGoldData = goldData.filter(item => item.userCode && item.userCode.includes(searchQuery));
  const filteredSilverData = silverData.filter(item => item.userCode && item.userCode.includes(searchQuery));
  
  const renderTable = (data, type) => (
    <table className="min-w-full bg-white mt-6">
      <thead>
        <tr>
          <th className="py-2 px-4 border-b">Nome</th>
          <th className="py-2 px-4 border-b">Telefono</th>
          <th className="py-2 px-4 border-b">E-mail</th>
          <th className="py-2 px-4 border-b">Messaggio</th>
          <th className="py-2 px-4 border-b">Prezzo</th>
          <th className="py-2 px-4 border-b">Caratura</th>
          <th className="py-2 px-4 border-b">Grammi</th>
          <th className="py-2 px-4 border-b">Timestamp</th>
          <th className="py-2 px-4 border-b">Stato</th>
          <th className="py-2 px-4 border-b">Codice Utente</th>
          <th className="py-2 px-4 border-b">Azioni</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id} className={isDataValid(item.timestamp) ? 'bg-green-100' : 'bg-red-100'}>
            <td className="py-2 px-4 border-b">{item.name}</td>
            <td className="py-2 px-4 border-b">{item.phone}</td>
            <td className="py-2 px-4 border-b">{item.email}</td>
            <td className="py-2 px-4 border-b">{item.message}</td>
            <td className="py-2 px-4 border-b">{item.price}</td>
            <td className="py-2 px-4 border-b">{item.carat}</td>
            <td className="py-2 px-4 border-b">{item.grams}</td>
            <td className="py-2 px-4 border-b">{new Date(item.timestamp).toLocaleString()}</td>
            <td className="py-2 px-4 border-b">
              {item.status === 'in lavorazione' ? `In lavorazione presso: ${item.storeName}` : (isDataValid(item.timestamp) ? 'Valid' : 'Expired')}
            </td>
            <td className="py-2 px-4 border-b">{item.userCode}</td>
            <td className="py-2 px-4 border-b">
              {item.status !== 'in lavorazione' && (
                <button
                  onClick={() => handlePresoInLavorazione(item, type)}
                  className="mr-2 px-4 py-2 bg-yellow-500 text-white font-semibold rounded-md shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                >
                  Preso in lavorazione
                </button>
              )}
              <button
                onClick={() => handleDelete(item.id, type)}
                className="px-4 py-2 bg-red-500 text-white font-semibold rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              >
                Elimina
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="w-full mx-auto pt-20 p-6 bg-[#ffffff63] rounded-lg shadow-md">
      <h2 className="text-2xl text-[#0d3960] font-bold mb-4">Pannello valutazioni</h2>

      <input
        type="text"
        value={searchQuery}
        onChange={handleSearch}
        placeholder="Cerca per codice utente..."
        className="mt-4 p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      />

      <h3 className="text-xl text-[#0d3960] mt-10 font-bold mb-2">Valutazione Oro</h3>
      {renderTable(filteredGoldData, 'OroBloccato')}

      <h3 className="text-xl text-[#0d3960] mt-10 font-bold mb-2">Valutazione Argento</h3>
      {renderTable(filteredSilverData, 'ArgentoBloccato')}

      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Inserisci nome del punto vendita"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="text-2xl text-[#0d3960] font-bold mb-4">Inserisci nome del punto vendita</h2>
        <input
          type="text"
          value={storeName}
          onChange={(e) => setStoreName(e.target.value)}
          placeholder="Nome del punto vendita"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
        <div className="mt-4">
          <button
            onClick={handleSave}
            className="mr-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Salva
          </button>
          <button
            onClick={() => setShowModal(false)}
            className="px-4 py-2 bg-gray-500 text-white font-semibold rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annulla
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminPage;
