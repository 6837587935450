import React, { useEffect } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const PreviewImageModal = ({ imageSrc, onClose, onConfirm }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open

        return () => {
            document.body.style.overflow = 'unset'; // Enable scrolling after modal is closed
        };
    }, []);

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            className="relative bg-white rounded-lg shadow-lg p-4 w-full max-w-2xl mx-auto z-60"
        >
            <button onClick={onClose} className="absolute top-0 right-0 mt-4 mr-4 text-gray-700 text-2xl">
                &times;
            </button>
            <h2 className="text-2xl text-[#0d3960] font-bold mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Anteprima
            </h2>
            <div className="flex justify-center items-center mb-4">
                <img src={imageSrc} alt="Preview" className="max-h-96" />
            </div>
            <div className="flex justify-end space-x-4">
                <button
                    onClick={onClose}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                    Cancel
                </button>
                <button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Confirm
                </button>
            </div>
        </Modal>
    );
};

export default PreviewImageModal;
