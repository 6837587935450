import React from 'react';
import { Link } from 'react-router-dom';

function ShopByCategory() {
  return (
    <div className="flex justify-center items-center bg-white">
      <div className="2xl:mx-auto 2xl:container py-8 px-4 sm:px-6 xl:px-20 2xl:px-0 w-full">
        <div className="flex flex-col justify-center items-center space-y-10">
          <div className="flex flex-col justify-center items-center"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4  w-full">
            <div className="relative group flex justify-center items-center h-full w-full">
              <img
                className="object-center object-cover h-full w-full border border-black"
                src="https://torellidiamonds.it/wp-content/uploads/2018/03/LD039.jpg"
                alt="girl-image"
              />
              <Link to="https://100carati.com/collections/anelli-nuovi" className="dark:bg-gray-800 dark:text-white text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                Anelli
              </Link>
              <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50 rounded-lg"></div>
            </div>

            <div className="flex flex-col space-y-4 md:space-y-8 mt-4 md:mt-0">
              <div className="relative group flex justify-center items-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full border border-black"
                  src="https://m.media-amazon.com/images/I/81GeaNAju-L.jpg"
                  alt="shoe-image"
                />
                <Link to="https://100carati.com/collections/bracciali-nuovi-100carati" className="dark:bg-gray-800 text-center dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                  Bracciali
                </Link>
                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50 rounded-lg"></div>
              </div>
              <div className="relative group flex justify-center items-center h-full w-full">
                <img
                  className="object-center object-cover h-full w-full border border-black"
                  src="https://www.lucacarati.it/admin/public/articolo/2019/12/12/ef8b6750bc925d092677472c8bc879f4/thumb1_C1144B2_1576146542.jpg"
                  alt="watch-image"
                />
                <Link to="https://100carati.com/collections/orecchini-nuovi" className="dark:bg-gray-800 text-center dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                  Orecchini
                </Link>
                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50 rounded-lg"></div>
              </div>
            </div>

            <div className="relative group justify-center items-center h-full w-full hidden lg:flex">
              <img
                className="object-center object-cover h-full w-full border border-black"
                src="https://torellidiamonds.it/wp-content/uploads/2018/03/LD039.jpg"
                alt="girl-image"
              />
              <Link to="https://100carati.com/collections/collane-collier-nuovi" className="dark:bg-gray-800 text-center dark:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bottom-4 z-10 absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white rounded-lg shadow-lg hover:shadow-xl transition transform hover:-translate-y-1">
                Collane
              </Link>
              <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50 rounded-lg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopByCategory;