import React, { useState } from 'react';
import { auth, app } from '../../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set, getDatabase, serverTimestamp } from 'firebase/database';

const AdminPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [userType, setUserType] = useState('operatore'); // Default a 'operatore'
  const db = getDatabase(app);

  const handleCreateUser = async (email, password, username) => {
    try {
      // Creazione dell'utente con email e password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  
      // Ottieni l'ID dell'utente appena creato
      const userId = userCredential.user.uid;
  
      // Salva l'utente nel Realtime Database con username, email, tipo di utente e timestamp
      await set(ref(db, `operators/${userId}`), {
        username: username,
        email: email,
        userType: userType,
        timestamp: serverTimestamp()
      });
  
      alert('Utente creato con successo!');
    } catch (error) {
      console.error('Errore durante la creazione dell\'utente:', error.message);
      alert('Si è verificato un errore durante la creazione dell\'utente. Controlla la console per i dettagli.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">Crea Nuovo Utente</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={(e) => { e.preventDefault(); handleCreateUser(email, password, username); }}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-4"
          />
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700">Tipo di Utente</label>
            <select
              className="mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="operatore">Operatore</option>
              <option value="superUser">Super User</option>
            </select>
          </div>
          <button
            type="submit"
            className="w-full mt-6 bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Crea Utente
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminPage;
