// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDqVcMxIg0iVLsmnD-BrbfNH3gzG-Mx6rg",
    authDomain: "euroxoro-120de.firebaseapp.com",
    databaseURL: "https://euroxoro-120de-default-rtdb.firebaseio.com",
    projectId: "euroxoro-120de",
    storageBucket: "euroxoro-120de.appspot.com",
    messagingSenderId: "439099435388",
    appId: "1:439099435388:web:35e61a86b3d059bea41cf9",
    measurementId: "G-DH3TXR7HV6"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);

export { app, auth, database, storage };
