import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,  // Disattiva i bottoni "next" e "prev"

  };

  return (
    <Slider {...settings}>
      <div className="flex flex-col items-center bg-[#0d3960] text-center p-8 shadow-lg">
        <h2 className="text-2xl font-bold text-white mb-4">DICONO DI NOI</h2>
        <p className="text-lg text-white mb-4">
          Personale gentile e disponibile, trasparenza sui prezzi, spiegato tutto con la massima competenza, la giusta dose di burocrazia per rendere tutto trasparente, CONSIGLIATO
        </p>
        <span className="text-white font-semibold">GIOVANNI C.</span>
      </div>
      <div className="flex flex-col items-center bg-[#0d3960] text-center p-8 shadow-lg">
        <h2 className="text-2xl font-bold text-white mb-4">DICONO DI NOI</h2>
        <p className="text-lg text-white mb-4">
          Un servizio eccellente, staff molto professionale e competente. Consiglio vivamente!
        </p>
        <span className="text-white font-semibold">MARIA P.</span>
      </div>
      <div className="flex flex-col items-center bg-[#0d3960] text-center p-8 shadow-lg">
        <h2 className="text-2xl font-bold text-white mb-4">DICONO DI NOI</h2>
        <p className="text-lg text-white mb-4">
          Ottimo servizio, prezzi onesti e personale cortese. Tornerò sicuramente!
        </p>
        <span className="text-white font-semibold">LUCA M.</span>
      </div>
    </Slider>
  );
};

export default Testimonial;
