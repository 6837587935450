// ActionButton.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes, faHistory } from '@fortawesome/free-solid-svg-icons';

const TextActionButton = ({ isEditing, onEdit, onSave, onCancel, onHistory, isDisabled }) => {
    return (
        <div className="absolute inline-flex space-x-2" style={{ right: isEditing ? '-4.5rem' : '-2.5rem', top: '15%', transform: 'translateY(-90%)' }}>
            {isEditing ? (
                <>
                    <button
                        onClick={onSave}
                        disabled={isDisabled}
                        className="p-2 bg-green-500 text-white rounded-full shadow hover:bg-green-600 disabled:bg-gray-300 inline-flex items-center justify-center"
                        style={{ width: '2.5rem', height: '2.5rem' }}
                    >
                        <FontAwesomeIcon icon={faSave} style={{ width: '1.5rem', height: '1.5rem' }} />
                    </button>
                    <button
                        onClick={onCancel}
                        className="p-2 bg-red-500 text-white rounded-full shadow hover:bg-red-600 inline-flex items-center justify-center"
                        style={{ width: '2.5rem', height: '2.5rem' }}
                    >
                        <FontAwesomeIcon icon={faTimes} style={{ width: '1.5rem', height: '1.5rem' }} />
                    </button>
                </>
            ) : (
                <>
                    <button
                        onClick={onEdit}
                        className="p-2 bg-gray-200 text-gray-700 rounded-full shadow hover:bg-gray-300 inline-flex items-center justify-center"
                        style={{ width: '2.5rem', height: '2.5rem' }}
                    >
                        <FontAwesomeIcon icon={faEdit} style={{ width: '1.5rem', height: '1.5rem' }} />
                    </button>
                    <button
                        onClick={onHistory}
                        className="p-2 bg-gray-200 text-gray-700 rounded-full shadow hover:bg-gray-300 inline-flex items-center justify-center"
                        style={{ width: '2.5rem', height: '2.5rem' }}
                    >
                        <FontAwesomeIcon icon={faHistory} style={{ width: '1.5rem', height: '1.5rem' }} />
                    </button>
                </>
            )}
        </div>
    );
};

export default TextActionButton;
