import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { app } from '../../../firebase'; // Assicurati che l'istanza Firebase sia correttamente importata
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LatestArticles = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch articles data from Firebase Realtime Database
        const db = getDatabase(app);
        const articlesRef = ref(db, 'blog'); // Assuming 'blog' is the path to articles in your database
        onValue(articlesRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const articlesArray = Object.keys(data).map((key) => ({
              id: key,
              ...data[key],
            }));
            setArticles(articlesArray);
          }
        });

        // Fetch images from Firebase Storage
        const storage = getStorage(app);
        for (let i = 0; i < articles.length; i++) {
          const imageUrl = await getDownloadURL(
            storageRef(storage, `images/${articles[i].image}`)
          );
          articles[i].imageUrl = imageUrl;
        }
        setArticles([...articles]); // Update state with updated articles array containing image URLs
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  if (articles.length === 0) {
    return null;
  }

  const settings = {
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section id="blog" className="text-center text-neutral-800 max-w-7xl mx-auto"> {/* Aggiunta della classe mx-auto per centrare il contenuto */}
      <h2 className="text-2xl font-bold text-[#0d3960] md:text-2xl mt-5 mb-10">
        ULTIME NOTIZIE
      </h2>
      <div className="mx-auto"> {/* Aggiunta di una classe per centrare il contenuto all'interno del padre */}
        <Slider {...settings}>
          {articles.map((article) => (
            <div key={article.id} className={`mb-8 lg:mb-0`}>
              <div className="relative block w-[20rem] mx-auto rounded-lg border border-yellow-500 shadow-lg p-4">
                <div className="flex">
                  <div className="relative p-2 overflow-hidden rounded-lg bg-cover bg-no-repeat">
                    <img
                      src={article.imageUrl}
                      className="w-72 h-44 object-cover rounded-lg"
                      alt={article.title}

                    />
                    <Link to={`/article/${article.id}`}>
                      <div
                        className="absolute justify-center bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"
                        style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}
                      ></div>
                    </Link>
                  </div>
                </div>
                <div className="p-3">
                  <h5 className="mb-3 text-lg font-bold text-[#0d3960]">
                    {article.title}
                  </h5>
                  <p className="mb-4 pb-2 text-[#0d3960]">{article.content}</p>
                  {/* <Link
                    to={`/article/${article.id}`}
                    className="inline-block rounded-full bg-[#0d3960] px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  >
                    Leggi l'articolo
                  </Link> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default LatestArticles;
