import React, { useContext, useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { signOut } from 'firebase/auth';
import { auth, database } from '../firebase'; // Adjust the import path as necessary
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom'; // Assumi di aver importato correttamente react-router-dom
import TextLabel from './editLabelComponents/textLabel';
import { AuthContext } from '../App';
import { ref, get, update } from 'firebase/database';

Modal.setAppElement('#root');

const Footer = () => {
  const [user] = useAuthState(auth);
  const { userType } = useContext(AuthContext);

  const [links, setLinks] = useState({
    facebook: '',
    instagram: '',
    pinterest: '',
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchLinks = async () => {
      const dbRef = ref(database, 'social');
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        setLinks(snapshot.val());
      }
    };

    fetchLinks();
  }, []);

  const handleLinkChange = (e) => {
    const { name, value } = e.target;
    setLinks((prevLinks) => ({ ...prevLinks, [name]: value }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = async () => {
    const dbRef = ref(database, 'social');
    await update(dbRef, links);
    console.log('Saved links:', links);
    closeModal();
  };

  const handleSignOut = () => {
    signOut(auth);
  };

  return (
    <footer className="bg-[#0d3960] text-center text-white">
      <div className="flex justify-center py-3">
        <Link
          to={links.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 bg-[#0c3050] rounded-full w-10 h-10 flex items-center justify-center hover:bg-[#3b5998] transition-colors duration-300"
        >
          <FontAwesomeIcon icon={faFacebookF} size="lg" className="text-white" />
        </Link>
        <Link
          to={links.instagram}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 bg-[#0c3050] rounded-full w-10 h-10 flex items-center justify-center hover:bg-[#e1306c] transition-colors duration-300"
        >
          <FontAwesomeIcon icon={faInstagram} size="lg" className="text-white" />
        </Link>
        <Link
          to={links.pinterest}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 bg-[#0c3050] rounded-full w-10 h-10 flex items-center justify-center hover:bg-[#bd081c] transition-colors duration-300"
        >
          <FontAwesomeIcon icon={faPinterest} size="lg" className="text-white" />
        </Link>
      </div>

      {userType === 'superUser' && (
        <div className="p-4 text-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
          <button onClick={openModal} className="text-white bg-blue-500 hover:bg-blue-700 px-4 py-2 rounded">
            Modifica link social
          </button>
          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Modifica Link Social"
            className="bg-white p-6 rounded shadow-lg max-w-lg mx-auto my-10"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          >
            <h2 className="text-2xl mb-4">Modifica Link Social</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Facebook Link</label>
                <input
                  type="text"
                  name="facebook"
                  value={links.facebook}
                  onChange={handleLinkChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Instagram Link</label>
                <input
                  type="text"
                  name="instagram"
                  value={links.instagram}
                  onChange={handleLinkChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Pinterest Link</label>
                <input
                  type="text"
                  name="pinterest"
                  value={links.pinterest}
                  onChange={handleLinkChange}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={closeModal}
                  className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                >
                  Chiudi
                </button>
                <button
                  type="button"
                  onClick={handleSave}
                  className="bg-green-500 hover:bg-green-700 text-white px-4 py-2 rounded"
                >
                  Salva
                </button>
              </div>
            </form>
          </Modal>
        </div>
      )}

      <div className="p-4 text-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <div className='text-center'>
          <TextLabel
            keyProp="SEDE_LEGALE"
            customClass=""
            tagType="div"
          />
          <TextLabel
            keyProp="PARTITA_IVA"
            customClass="-mt-3"
            tagType="div"
          />
          <TextLabel
            keyProp="COD_SDI"
            customClass="-mt-3"
            tagType="div"
          />
          <TextLabel
            keyProp="EMAIL_AMMIN"
            customClass="-mt-3"
            tagType="div"
          />
          <TextLabel
            keyProp="EMAIL_PEC"
            customClass="-mt-3"
            tagType="div"
          />
          <div className="mt-4">
            {user ? (
              <button onClick={handleSignOut} className="text-white bg-red-500 hover:bg-red-700 px-2 py-2 rounded">
                Torna a visione Utente
              </button>
            ) : (
              <a href="/login" className="text-black bg-white hover:bg-gray-700 px-2 py-2 rounded">
                Admin
              </a>
            )}
          </div>
        </div>
        <div className="mt-4">
          <div>
            <a href="/candidati" className="text-white mr-4">Lavora con noi</a>
            <a href="/privacy" className="text-white mr-4">Privacy policy</a>
            <a href="/condizioni" className="text-white">Termini e condizioni</a>
          </div>
          {userType === 'superUser' && (
            <div>
              <a href="/privacy" className="text-white mr-4">Carica nuova Privacy Policy</a>
              <a href="/condizioni" className="text-white">Carica nuovi Termini e Condizioni</a>
            </div>
          )}
        </div>
        <div className="mt-4">2024 DM Project ©</div>
      </div>
    </footer>
  );
};

export default Footer;
