// CustomTextarea.js
import React, { useEffect, useRef, useState } from 'react';

const CustomTextarea = ({ value, onChange, spanRef }) => {
    const [dimensions, setDimensions] = useState({ width: 'auto', height: 'auto' });
    const inputRef = useRef(null);
    const widthCalculatorRef = useRef(null);

    useEffect(() => {
        calculateDimensions();
    }, [value]);

    useEffect(() => {
        const handleResize = () => {
            calculateDimensions();
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const calculateDimensions = () => {
        const calculator = widthCalculatorRef.current;
        calculator.style.width = `${spanRef.current.offsetWidth}px`;
        calculator.innerText = value;
        setDimensions({
            width: calculator.offsetWidth + 10,
            height: calculator.offsetHeight + 10,
        });
    };

    const handleOnChange = (e) => {
        const cursorPosition = e.target.selectionStart;
        onChange(e);
        setTimeout(() => {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };

    return (
        <>
            <textarea
                ref={inputRef}
                value={value}
                onChange={handleOnChange}
                className="absolute border rounded p-1 bg-white text-black"
                autoFocus
                style={{
                    left: 0,
                    top: 0,
                    background: 'white',
                    border: 'none',
                    padding: 0,
                    width: `${dimensions.width}px`,
                    height: `${dimensions.height}px`,
                    overflow: 'hidden',
                    resize: 'none',
                }}
            />
            <span ref={widthCalculatorRef} style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'pre-wrap' }}>
                {value}
            </span>
        </>
    );
};

export default CustomTextarea;
