import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { app } from '../../../firebase'; // Assicurati di importare Firebase

const CustomerServiceContact = () => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    messaggio: '',
    privacy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const db = getDatabase(app); // Passa l'istanza Firebase app qui
      const contactsRef = ref(db, 'customer_service_contacts');
      
      // Aggiungi il timestamp a formData
      const dataWithTimestamp = {
        ...formData,
        timestamp: Date.now(), // Salva il timestamp attuale
      };
  
      await push(contactsRef, dataWithTimestamp);
      
      setFormData({
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        messaggio: '',
        privacy: false,
      });
  
      alert('Richiesta inviata con successo!');
    } catch (error) {
      console.error("Errore durante l'invio della richiesta:", error);
      alert(
        "Si è verificato un errore durante l'invio della richiesta. Riprova più tardi."
      );
    }
  };

  return (
    <>
      <div className="bg-gray-100">
        <h2 className="text-center text-[#0d3960] pt-8 text-2xl mt-6 font-bold ">
          COMPILA IL MODULO PER MAGGIORI INFORMAZIONI
        </h2>
        <div className="customer-service-contact flex justify-center mt-4 px-3 items-center bg-gray-100">
          <div className=" px-8 mt-2 rounded-lg w-full max-w-3xl mb-8">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="nome" className="block text-[#0d3960] mb-1">
                  Nome
                </label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="cognome" className="block text-[#0d3960] mb-1">
                  Cognome
                </label>
                <input
                  type="text"
                  id="cognome"
                  name="cognome"
                  value={formData.cognome}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-[#0d3960] mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="telefono" className="block text-[#0d3960] mb-1">
                  Telefono
                </label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="messaggio"
                  className="block text-[#0d3960] mb-1"
                >
                  Messaggio
                </label>
                <textarea
                  id="messaggio"
                  name="messaggio"
                  value={formData.messaggio}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  rows="4"
                  required
                />
              </div>
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  checked={formData.privacy}
                  onChange={handleChange}
                  className="mr-2 bg-transparent"
                  required
                />
                <label htmlFor="privacy" className="text-[#0d3960]">
                  Acconsento al trattamento dei dati personali
                </label>
              </div>
              <button
                type="submit"
                className="bg-[#0d3960] border border-[#0d3960] text-white py-2 px-6 rounded-md hover:bg-red-800 focus:outline-none focus:bg-red-800 w-full"
              >
                Invia
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerServiceContact;
