import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditArticleForm = () => {
  const { articleId } = useParams();
  const db = getDatabase();
  const storage = getStorage();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const [articleData, setArticleData] = useState({
    title: '',
    content: '',
    imageUrl: '',
  });

  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        const articleRef = ref(db, `blog/${articleId}`);
        const snapshot = await get(articleRef);
        if (snapshot.exists()) {
          const articleData = snapshot.val();
          setArticleData(articleData);
        }
      } catch (error) {
        console.error('Error fetching article data:', error);
      }
    };

    fetchArticleData();
  }, [db, articleId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleData({ ...articleData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const handleDeleteImage = async () => {
    try {
      if (articleData.imageUrl) {
        const imageRef = storageRef(storage, articleData.imageUrl);
        await deleteObject(imageRef);

        setArticleData({ ...articleData, imageUrl: '' });

        const articleRef = ref(db, `blog/${articleId}`);
        await set(articleRef, { ...articleData, imageUrl: '' });

        toast.success('Immagine eliminata con successo!');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
      toast.error('Si è verificato un errore durante l\'eliminazione dell\'immagine.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      let updatedArticleData = { ...articleData };

      if (imageFile) {
        const imageRef = storageRef(storage, `images/${articleId}_${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        const imageUrl = await getDownloadURL(imageRef);
        updatedArticleData = { ...updatedArticleData, imageUrl };
      }

      const articleRef = ref(db, `blog/${articleId}`);
      await set(articleRef, updatedArticleData);

      toast.success('Articolo aggiornato con successo!');
    } catch (error) {
      console.error('Error updating article:', error);
      toast.error('Si è verificato un errore durante l\'aggiornamento dell\'articolo.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleSubmit} className="w-full max-w-lg mx-auto p-8 bg-white shadow-lg rounded-lg ">
        <div className="mb-4 mt-20">
          <label htmlFor="title" className="block text-gray-700 font-bold mb-2 ">Titolo:</label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Inserisci il titolo dell'articolo"
            value={articleData.title}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image" className="block text-gray-700 font-bold mb-2">Immagine:</label>
          {articleData.imageUrl && (
            <div className="flex items-center mb-2">
              <img src={articleData.imageUrl} alt="Article" className="w-16 h-16 mr-2" />
              <button
                type="button"
                onClick={handleDeleteImage}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
              >
                Elimina
              </button>
            </div>
          )}
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
            className="input-field"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="content" className="block text-gray-700 font-bold mb-2">Contenuto:</label>
          <textarea
            id="content"
            name="content"
            placeholder="Inserisci il contenuto dell'articolo"
            value={articleData.content}
            onChange={handleInputChange}
            className="input-field"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? 'Sto salvando le modifiche...' : 'Salva modifiche'}
        </button>
      </form>
    </div>
  );
};

export default EditArticleForm;

