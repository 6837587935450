import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

// Funzione di utilità per lo scrolling animato
const smoothScroll = (targetId) => {
  const targetElement = targetId
    ? document.getElementById(targetId)
    : document.body;
  if (targetElement) {
    window.scrollTo({
      top: targetElement.offsetTop,
      behavior: 'smooth',
    });
  }
};

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const { pathname } = useLocation();
  const navbarRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const handleAnchorClick = (e, targetId) => {
    e.preventDefault();
    smoothScroll(targetId);
    setIsNavCollapsed(true); // Chiudi il menu dopo il click su mobile
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavCollapsed(true);
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setIsNavCollapsed(true); // Chiudi il menu quando si cambia pagina
  }, [pathname]);

  const menuRef = useRef(null);

  // Chiude il menu quando si clicca fuori
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav
      ref={navbarRef}
      className="fixed z-50 flex w-full flex-wrap items-center justify-between bg-[#0d3960] py-3 text-neutral-200 shadow-lg lg:flex-wrap lg:justify-start opacity-95"
    >
      <div className="flex w-full flex-wrap items-center justify-between px-6">
        <div className="flex items-center justify-between w-full lg:w-auto">
          <Link to="/">
            <img
              src="./logo.png"
              alt="Euroxoro Logo"
              className="lg:hidden"
              style={{ height: '5vh', width: 'auto' }}
            />
          </Link>
          <button
            className="block border-0 bg-transparent py-2 px-2.5 text-neutral-200 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
            type="button"
            onClick={handleNavCollapse}
            aria-controls="navbarSupportedContent1"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
          >
            <span className="[&>svg]:w-6">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="bars"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            </span>
          </button>
        </div>
        <div className="hidden w-full items-center justify-between lg:flex lg:w-auto">
          <Link to="/" className="mr-auto">
            <img
              src="./logo.png"
              alt="Euroxoro Logo"
              className=""
              style={{ height: '5vh', width: 'auto' }}
            />
          </Link>
        </div>
        <div
          className={`${
            isNavCollapsed ? 'hidden' : 'flex'
          } w-full flex-grow items-center lg:flex lg:w-auto lg:flex-grow-0 lg:justify-center`}
          id="navbarSupportedContent1"
        >
          <ul className="list-style-none mr-auto flex flex-col pl-0 lg:flex-row">
            <li className="p-2">
              <Link
                to="/sedi"
                className="nav-link p-0 opacity-60 hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90"
              >
                Le nostre sedi
              </Link>
            </li>
            <li className="p-2">
              <Link
                to="/contattaci"
                className="nav-link p-0 opacity-60 hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90"
              >
                Assistenza Clienti
              </Link>
            </li>
            {/* <li className="p-2">
              <Link
                to="/franchasing"
                className="nav-link p-0 opacity-60 hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90"
              >
                Franchasing
              </Link>
            </li> */}
            <li className="p-2">
              <div className="relative group" ref={menuRef}>
                <button
                  className={`nav-link p-0 opacity-60 focus:opacity-80 disabled:text-black/30 lg:px-2 ${
                    isOpen ? 'text-black/90' : ''
                  }`}
                  onClick={toggleMenu}
                >
                  I nostri servizi{' '}
                  <FontAwesomeIcon icon={faChevronDown} className="ml-1" />
                </button>
                <div
                  className={`absolute left-0 mt-6 w-48 ${
                    isOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
                  }`}
                >
                  <ul className="bg-white text-[#0d3960] shadow-lg transition-opacity duration-300 rounded-b-lg">
                    <Link to="/compro-oro" onClick={closeMenu}>
                      <li className="px-4 py-2 hover:bg-gray-200">
                        Compro oro
                      </li>
                    </Link>
                    <Link to="/oro-investimento" onClick={closeMenu}>
                      <li className="px-4 py-2 hover:bg-gray-200">
                        Oro da investimento
                      </li>
                    </Link>
                    <Link to="/vendita-gioielli" onClick={closeMenu}>
                      <li className="px-4 py-2 hover:bg-gray-200">
                        Vendita gioielli
                      </li>
                    </Link>
                    <Link to="/laboratorio-orafo" onClick={closeMenu}>
                      <li className="px-4 py-2 hover:bg-gray-200 rounded-b-lg">
                        Laboratorio orafo
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            </li>
            <li className="p-2">
              <Link
                to="/sostenibilità"
                className="nav-link p-0 opacity-60 hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90"
              >
                Sostenibilità
              </Link>
            </li>

            <li className="p-2 hidden lg:block">
              <a
                href="https://100carati.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-0 rounded bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 py-3 text-sm font-medium uppercase leading-snug text-white hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90 hover:underline"
              >
                Outlet del gioiello
              </a>
            </li>
            <li className="p-2 lg:hidden mb-3">
              <a
                href="https://100carati.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-0 px-8 rounded bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 py-3 text-sm font-medium uppercase leading-snug text-white hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90 hover:underline"
              >
                Outlet del gioiello
              </a>
            </li>
            <li className="p-2 hidden lg:block">
              <Link
                to={pathname === '/' ? '#vendi' : '/#vendi'}
                className="p-0 rounded bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 py-3 text-sm font-medium uppercase leading-snug text-white hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90 hover:underline"
              >
                VENDI E GUADAGNA
              </Link>
            </li>
            <li className="p-2 lg:hidden">
              <Link
                to={pathname === '/' ? '#vendi' : '/#vendi'}
                className="p-0 rounded bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 px-10 py-3 text-sm font-medium uppercase leading-snug text-white hover:opacity-80 focus:opacity-80 disabled:text-black/30 lg:px-2 [&.active]:text-black/90 hover:underline"
              >
                VENDI E GUADAGNA
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
