import React from 'react';

const sedi = [
  {
    posizione: [41.4643, 12.9056],
    indirizzo: 'Latina via Isonzo 106',
    telefono: '0773487512',
    orario: '09:00/13:00 - 15:30/19:00',
    email: 'latina2@euroxoro.it',
    descrizione: 'Oltre attività di compro oro è anche outlet del gioiello'
  },
  {
    posizione: [41.4662, 12.8925],
    indirizzo: 'Latina via Ezio 2',
    telefono: '07731761052',
    orario: '08:30/13:00 - 16:00/19:30',
    email: 'latina3@euroxoro.it'
  },
  // Altre sedi...
];

const About = () => {
  return (
    <section id='chisiamo'>
      <div className="container my-24 mx-auto px-6">
        <section className="mb-32">
          <style>
            {`
              @media (min-width: 992px) {
                #cta-img-nml-50 {
                  margin-left: 50px;
                }
              }
            `}
          </style>

          <div className="flex flex-wrap">
            <div className="mb-12 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-5/12">
              <div className="flex lg:py-12">
                <img
                  src="https://previews.123rf.com/images/lanksv/lanksv2102/lanksv210200801/163316836-molti-gioielli-e-soldi-d-oro-e-d-argento-concetto-di-banco-dei-pegni-negozio-di-gioielli-primo.jpg"
                  className="w-full rounded-lg shadow-lg"
                  id="cta-img-nml-50"
                  style={{ zIndex: '10' }}
                  alt=""
                />
              </div>
            </div>

            <div className="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <div className="flex h-full items-center rounded-lg bg-yellow-500 p-6 text-center text-white lg:pl-12 lg:text-left">
                <div className="lg:pl-12">
                  <h2 className="mb-6 text-3xl font-bold">Su di noi</h2>
                  <p className="mb-6 pb-2 lg:pb-0">
                    Scopri l'eleganza senza tempo dell'oro e dell'argento nella nostra collezione esclusiva. Ogni pezzo racconta una storia di raffinatezza e prestigio, plasmato con maestria artigianale e passione. Dai classici gioielli agli innovativi design contemporanei, il nostro negozio è il tuo rifugio per lussuose creazioni che brillano di bellezza senza tempo. Entra nel mondo del lusso e dell'eleganza con noi.
                  </p>

                  <div className="mx-auto mb-6 flex flex-col md:flex-row md:justify-around xl:justify-start">
                    <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="mr-2 h-4 w-4"
                      >
                        <path
                          fill="currentColor"
                          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        ></path>
                      </svg>
                      Team esperto
                    </p>

                    <p className="mx-auto mb-4 flex items-center md:mx-0 md:mb-2 lg:mb-0 xl:mr-20">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="mr-2 h-4 w-4"
                      >
                        <path
                          fill="currentColor"
                          d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                        ></path>
                      </svg>
                      Migliore qualità
                    </p>

                    <p className="mx-auto mb-2 flex items-center md:mx-0 lg:mb-0">
                     
                        
                      Prezzi migliori
                    </p>
                  </div>

                  {/* Informazioni sulle sedi */}
                  <div>
                    <h3 className="text-xl font-semibold mb-4">Le nostre sedi</h3>
                    <ul className="mb-6">
                      {sedi.map((sede, index) => (
                        <li key={index} className="mb-4">
                          <h4 className="font-semibold mb-2">{sede.indirizzo}</h4>
                          <p>Telefono: {sede.telefono}</p>
                          <p>Email: {sede.email}</p>
                          <p>Orario: {sede.orario}</p>
                          {sede.descrizione && <p>{sede.descrizione}</p>}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default About;
