import React, { useState, useEffect, createContext, useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Homepage from './components/pages/home/Homepage';
import About from './components/pages/about/About';
import Contattaci from './components/pages/contact/Contattaci';
import Lavora from './components/pages/contact/Lavora';
import Franchasing from './components/pages/contact/Franchasing';
import Login from './components/pages/auth/Login';
import AdminPanel from './components/pages/admin/AdminPanel';
import BlogManager from './components/pages/admin/BlogManager';
import Inventario from './components/pages/admin/Inventario';
import EditArticolo from './components/pages/admin/EditArticolo';
import Contacts from './components/pages/admin/Contacts';
import Candidature from './components/pages/admin/Candidature';
import PrezziBloccatiOro from './components/pages/admin/PrezziBloccatiOro';
import CreazioneUtenti from './components/pages/admin/CreazioneUtenti';
import Utenti from './components/pages/admin/Utenti';
import DettagliArticolo from './components/pages/articolo/DettagliArticolo';
import Sedi from './components/pages/sedi/Sedi';
import Privacy from './components/pages/policy/Privacy';
import Condizioni from './components/pages/policy/Condizioni';
import Register from './components/pages/auth/Register';
import { AppProvider } from './components/editLabelComponents/AppContext';
import ComproOro from './components/pages/servizi/ComproOro';
import OroInvestimento from './components/pages/servizi/OroInvestimento';
import VenditaGioielli from './components/pages/servizi/VenditaGioielli';
import LaboratorioOrafo from './components/pages/servizi/LaboratorioOrafo';
import PuntiVendita from './components/pages/puntivendita/PuntiVendita';
import OperatorDashboard from './components/pages/admin/operator/OperatorDashboard';
import { v4 as uuidv4 } from 'uuid'; // Importa la funzione v4 di UUID
import { app, auth } from './firebase';
import { getDatabase, ref, onValue } from 'firebase/database';
import { EditProvider } from './components/editLabelComponents/EditContext';
import './App.css';
import Sostenibilità from './components/pages/sostenibilità/Sostenibilità';
import PdfPage from './components/pages/policy/PdfPage';
import Layout from './Layout';

const AuthContext = createContext();

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('isLoggedIn')) || false;
    } catch {
      return false;
    }
  });
  const [userType, setUserType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const db = getDatabase(app);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);

        const userRef = ref(db, `operators/${user.uid}`);
        onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setUserType(data.userType);
          }
          setIsLoading(false);
        });
      } else {
        setIsLoggedIn(false);
        setUserType(null);
        setIsLoading(false);
      }
    });

    return unsubscribe;
  }, [db]);

  useEffect(() => {
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const ProtectedRoute = ({
    component: Component,
    requiredUserType,
    ...rest
  }) => {
    const { isLoggedIn, userType, isLoading } = useContext(AuthContext);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (!isLoggedIn || (requiredUserType && userType !== requiredUserType)) {
      return <Navigate to="/login" />;
    }

    return <Component {...rest} />;
  };

  return (
    <>
      <div
        id="whatsapp"
        className="qlwapp-free qlwapp-bubble qlwapp-bottom-right qlwapp-all qlwapp-rounded qlwapp-js-ready desktop"
      >
        <a
          className="qlwapp-toggle"
          data-action="open"
          data-phone="3518153599"
          data-message="Ciao! Vorrei avere maggiori informazioni 😄"
          href="https://wa.me/393518153599?text=ciao%2C%20ho%20bisogno%20di%20maggiori%20informazioni"
          target="_blank"
        >
          <span className="qlwapp-text">Contatta il servizio clienti</span>
          <i className="qlwapp-icon qlwapp-whatsapp-icon">
            <img src="https://cdn-icons-png.flaticon.com/512/174/174879.png" />
          </i>
          <i className="qlwapp-close" data-action="close"></i>
        </a>
      </div>
      <Router>
        <AppProvider>
          <AuthContext.Provider value={{ isLoggedIn, userType, isLoading }}>
            <EditProvider>
              <Layout>
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/" element={<Homepage />} />
                  <Route path="/chisiamo" element={<About />} />
                  <Route path="/contattaci" element={<Contattaci />} />
                  <Route path="/franchasing" element={<Franchasing />} />
                  <Route path="/candidati" element={<Lavora />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute
                        component={AdminPanel}
                        requiredUserType="superUser"
                      />
                    }
                  />
                  <Route
                    path="/aggiungiarticolo"
                    element={
                      <ProtectedRoute
                        component={BlogManager}
                        requiredUserType="superUser"
                      />
                    }
                  />
                  <Route
                    path="/inventario"
                    element={
                      <ProtectedRoute
                        component={Inventario}
                        requiredUserType="superUser"
                      />
                    }
                  />
                  <Route
                    path="/editarticolo/:articleId"
                    element={
                      <ProtectedRoute
                        component={EditArticolo}
                        requiredUserType="superUser"
                      />
                    }
                  />
                  <Route
                    path="/admin/PrezziBloccatiOro"
                    element={<ProtectedRoute component={PrezziBloccatiOro} />}
                  />
                  <Route
                    path="/vedicandidature"
                    element={<ProtectedRoute component={Candidature} />}
                  />
                  {/* <Route
                    path="/vedicandidature"
                    element={<Candidature />}
                  /> */}
                  <Route
                    path="/admin/CreazioneUtenti"
                    element={
                      <ProtectedRoute
                        component={CreazioneUtenti}
                        requiredUserType="superUser"
                      />
                    }
                  />
                  <Route
                    path="/admin/Utenti"
                    element={
                      <ProtectedRoute
                        component={Utenti}
                        requiredUserType="superUser"
                      />
                    }
                  />
                  <Route
                    path="/vedimessaggi"
                    element={<ProtectedRoute component={Contacts} />}
                  />
                  {/* <Route
                    path="/vedimessaggi"
                    element={<Contacts />}
                  /> */}
                  <Route
                    path="/dashboardoperatore"
                    element={<ProtectedRoute component={OperatorDashboard} />}
                  />
                  <Route path="/article/:articleId" element={<DettagliArticolo />} />
                  <Route path="/sedi" element={<Sedi />} />
                  <Route path="/privacy" element={<PdfPage pdfKey="pdfs/privacy.pdf" />} />
                  <Route path="/condizioni" element={<PdfPage pdfKey="pdfs/condizioni.pdf" />} />
                  <Route path="/compro-oro" element={<ComproOro />} />
                  <Route path="/oro-investimento" element={<OroInvestimento />} />
                  <Route path="/vendita-gioielli" element={<VenditaGioielli />} />
                  <Route path="/laboratorio-orafo" element={<LaboratorioOrafo />} />
                  <Route path="/PUNTI-VENDITA" element={<PuntiVendita />} />
                  <Route path="/sostenibilità" element={<Sostenibilità />} />
                </Routes>
              </Layout>
            </EditProvider>
          </AuthContext.Provider>
        </AppProvider>
      </Router>
    </>
  );
};

export { AuthContext };
export default App;
