import React, { useContext } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import ImageLabel from '../../editLabelComponents/imageLabel';
import TextLabel from '../../editLabelComponents/textLabel';
import { AuthContext } from '../../../App'; // Assicurati di importare AuthContext
import './PulsanteHero.css';

const Hero = () => {
  const { userType } = useContext(AuthContext);

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} next-arrow`} onClick={onClick}>
        <FaChevronRight />
      </div>
    );
  };

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} prev-arrow`} onClick={onClick}>
        <FaChevronLeft />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  const handleLinkClick = (e) => {
    if (userType === 'superUser') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <section id="home" className=" ">
      <Slider {...settings}>
        <div className="slide-container mt-20 bg-gradient-to-r from-[#c5a66a] via-[#e7c58083] to-[#c5a66a]">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 md:h-full flex flex-col justify-center text-center md:text-left">
              <div className="text-white text-2xl font-bold tracking-tight">
                <TextLabel
                  keyProp="HERO_A_P"
                  tagType="p"
                  customClass="mt-3 -mb-1 text-xl lg:text-2xl lg:mt-0"
                />
                <TextLabel
                  keyProp="HERO_A"
                  tagType="h1"
                  customClass="text-5xl lg:text-6xl mb-2"
                />
                <hr className="border-t border-white w-16 mx-auto md:mx-0 my-2" />
                {userType === 'superUser' ? (
                  <a className="buttonHero font-normal">
                    <TextLabel
                      keyProp="PULSANTE_HERO_A"
                      tagType="a"
                      customClass="font-normal"
                    />
                  </a>
                ) : (
                  <a href="sedi" className="buttonHero font-normal">
                    <TextLabel
                      keyProp="PULSANTE_HERO_A"
                      tagType="a"
                      customClass="font-normal"
                    />
                  </a>
                )}
              </div>
            </div>
            <div className="md:w-1/2 md:h-full mt-5 xl:mt-14">
              <ImageLabel
                keyProp="compro_oro_home"
                customClass="w-auto h-64 z-50 lg:h-[30rem] lg:w-full object-cover"
                tagType="img"
              />
            </div>
          </div>
        </div>
        <div className="slide-container mt-20 bg-gradient-to-r from-[#c5a66a] via-[#e7c58083] to-[#c5a66a]">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 md:h-full flex flex-col justify-center text-center md:text-left">
              <div className="text-white text-2xl font-bold tracking-tight">
                <TextLabel
                  keyProp="HERO_B_P"
                  tagType="p"
                  customClass="mt-3 -mb-1 text-xl lg:text-2xl lg:mt-0"
                />
                <TextLabel
                  keyProp="HERO_B"
                  tagType="h1"
                  customClass="text-5xl lg:text-6xl mb-2"
                />
                <hr className="border-t border-white w-16 mx-auto md:mx-0 my-2" />
                {userType === 'superUser' ? (
                  <a  className="buttonHero font-normal" >
                    <TextLabel
                      keyProp="PULSANTE_HERO_B"
                      tagType="a"
                      customClass="font-normal"
                    />
                  </a>
                ) : (
                  <a href="https://100carati.com" target="_blank" className="buttonHero font-normal">
                    <TextLabel
                      keyProp="PULSANTE_HERO_B"
                      tagType="a"
                      customClass="font-normal"
                    />
                  </a>
                )}
              </div>
            </div>
            <div className="md:w-1/2 md:h-full mt-5 xl:mt-14">
              <ImageLabel
                keyProp="outlet_gioiello"
                customClass="w-64 h-64 lg:h-[30rem] lg:w-[30rem] object-cover"
                tagType="img"
              />
            </div>
          </div>
        </div>
        <div className="slide-container mt-20 bg-gradient-to-r from-[#c5a66a] via-[#e7c58083] to-[#c5a66a]">
          <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 md:h-full flex flex-col justify-center text-center md:text-left">
              <div className="text-white text-2xl font-bold tracking-tight">
                <TextLabel
                  keyProp="HERO_C_P"
                  tagType="p"
                  customClass="mt-3 -mb-1 text-xl lg:text-2xl lg:mt-0"
                />
                <TextLabel
                  keyProp="HERO_C"
                  tagType="h1"
                  customClass="text-5xl lg:text-6xl mb-2"
                />
                <hr className="border-t border-white w-16 mx-auto md:mx-0 my-2" />
                {userType === 'superUser' ? (
                  <a  className="buttonHero font-normal" >
                    <TextLabel
                      keyProp="PULSANTE_HERO_C"
                      tagType="a"
                      customClass="font-normal"
                    />
                  </a>
                ) : (
                  <a href="contattaci" className="buttonHero font-normal">
                    <TextLabel
                      keyProp="PULSANTE_HERO_C"
                      tagType="a"
                      customClass="font-normal"
                    />
                  </a>
                )}
              </div>
            </div>
            <div className="md:w-1/2 md:h-full mt-5 xl:mt-14">
              <ImageLabel
                keyProp="valutazione"
                customClass="w-auto h-64 lg:h-[30rem] lg:w-full object-cover"
                tagType="img"
              />
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Hero;
