import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, remove } from 'firebase/database';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStorage, ref as storageRef, deleteObject } from 'firebase/storage';

const BlogInventory = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const db = getDatabase();
  const storage = getStorage();

  useEffect(() => {
    const articlesRef = ref(db, 'blog');
    const unsubscribe = onValue(articlesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const articleList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setArticles(articleList);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const filteredArticles = articles.filter((article) =>
    article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    article.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
    article.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDeleteArticle = async (articleId, imageUrl) => {
    try {
      // Rimuovi l'articolo dal Realtime Database
      const articleRef = ref(db, `blog/${articleId}`);
      await remove(articleRef);

      // Rimuovi l'immagine dallo Storage di Firebase
      const imageRef = storageRef(storage, imageUrl);
      await deleteObject(imageRef);

      toast.success('Articolo eliminato con successo!');
    } catch (error) {
      console.error('Errore durante l\'eliminazione dell\'articolo:', error);
      toast.error('Si è verificato un errore durante l\'eliminazione dell\'articolo.');
    }
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="container mx-auto p-4 ">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4 mt-20">Elenco degli Articoli</h1>
      <input
        type="text"
        placeholder="Cerca per titolo/contenuto"
        value={searchTerm}
        onChange={handleSearchTermChange}
        className="border border-gray-300 rounded px-3 py-1 mb-4"
      />
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Titolo</th>
            <th className="px-4 py-2">Contenuto</th>
            <th className="px-4 py-2">Azioni</th>
          </tr>
        </thead>
        <tbody>
          {filteredArticles.map((article) => (
            <tr key={article.id}>
              <td className="border px-4 py-2">{article.id}</td>
              <td className="border px-4 py-2">{article.title}</td>
              <td className="border px-4 py-2">{article.content}</td>
              <td className="border px-2 py-2">
                <Link
                  to={`/editarticolo/${article.id}`}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-2 rounded"
                >
                  Modifica
                </Link>
                <button
                  onClick={() => handleDeleteArticle(article.id, article.imageUrl)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Elimina
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BlogInventory;
