import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { app } from '../../../firebase'; // Ensure you import Firebase
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faChartLine,
  faPiggyBank,
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import FranchasingImg from '../../../img/FRANCHASING.png';
import TextLabel from '../../editLabelComponents/textLabel';
import ImageLabel from '../../editLabelComponents/imageLabel';

const FranchisingContact = () => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    email: '',
    telefono: '',
    privacy: false,
    marketing: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = getDatabase(app); // Pass the Firebase app instance here
      const contactsRef = ref(db, 'franchising_contacts');
      await push(contactsRef, formData);
      setFormData({
        nome: '',
        cognome: '',
        email: '',
        telefono: '',
        privacy: false,
        marketing: false,
      });
      alert('Richiesta inviata con successo!');
    } catch (error) {
      console.error("Errore durante l'invio della richiesta:", error);
      alert(
        "Si è verificato un errore durante l'invio della richiesta. Riprova più tardi."
      );
    }
  };

  return (
    <div className=" pt-16 lg:pt-20 ">
      <div className="relative w-full h-auto lg:h-[30rem] xl:h-[40rem] flex flex-wrap items-center justify-center">
        <div className="absolute inset-0 w-full h-52 lg:h-full">
          <ImageLabel
            keyProp="FRANCHASING"
            customClass="w-full h-full object-cover"
            tagType="img"
          />
          <div className="absolute inset-0 lg:bg-black opacity-50 "></div>
        </div>
        <div className="relative z-10 text-[#0d3960] lg:text-white flex flex-wrap items-center justify-start w-full">
          <div className="container mt-10 lg:mt-20 mx-auto px-4 flex flex-col lg:flex-row justify-start items-left lg:items-start gap-8">
            <div className="lg:text-left text-left mt-48 lg:mt-10">
              <p className="mb-2 text-2xl">Affidati alla nostra rete</p>
              <h1 className="text-4xl lg:text-6xl mb-2">FRANCHASING</h1>
              <hr className="border-t border-[#0d3960] lg:border-white w-16 mx-auto md:mx-0 my-2" />
            </div>
          </div>
        </div>
      </div>
      <div className=" text-[#0d3960] mb-7 flex flex-wrap items-start justify-center">
        <div className="container  mx-auto px-4 flex flex-col lg:flex-row justify-center items-start gap-8">
          <div className="flex flex-col space-y-8 mt-14 mobile-grid lg:w-1/2 order-1 lg:order-1">
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faHandshake}
                className="text-yellow-500 h-10 w-10 mr-4"
              />
              <div>
                <TextLabel
                  keyProp="FRCH_TITLE_A"
                  tagType="h3"
                  customClass="text-2xl font-semibold"
                />
                <TextLabel
                  keyProp="FRCH_PARAGRAPH_A"
                  tagType="P"
                  customClass=""
                />
              </div>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faChartLine}
                className="text-yellow-500 h-10 w-10 mr-4"
              />
              <div>
                <TextLabel
                  keyProp="FRCH_TITLE_B"
                  tagType="h3"
                  customClass="text-2xl font-semibold"
                />
                <TextLabel
                  keyProp="FRCH_PARAGRAPH_B"
                  tagType="P"
                  customClass=""
                />
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 order-1 lg:order-2 space-y-8 mt-14 mobile-grid">
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faPiggyBank}
                className="text-yellow-500 h-10 w-10 mr-4"
              />
              <div>
                <TextLabel
                  keyProp="FRCH_TITLE_C"
                  tagType="h3"
                  customClass="text-2xl font-semibold"
                />
                <TextLabel
                  keyProp="FRCH_PARAGRAPH_C"
                  tagType="P"
                  customClass=""
                />
              </div>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon
                icon={faStore}
                className="text-yellow-500 h-10 w-10 mr-4"
              />
              <div>
                <TextLabel
                  keyProp="FRCH_TITLE_D"
                  tagType="h3"
                  customClass="text-2xl font-semibold"
                />
                <TextLabel
                  keyProp="FRCH_PARAGRAPH_D"
                  tagType="P"
                  customClass=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="inline-block text-center rounded-lg border border-yellow-500 px-2">
          <a
            href="/punti-vendita"
            className="bg-gradient-to-r text-center from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300 text-2xl"
          >
            Scopri tutti i punti vendita EuroXOro
          </a>
        </div>
      </div>

      <div className="bg-gray-100">
        <h2 className="text-center text-[#0d3960] pt-8 text-2xl mt-6 font-bold ">
          COMPILA IL MODULO PER MAGGIORI INFORMAZIONI
        </h2>
        <div className="customer-service-contact flex justify-center mt-4 px-3 items-center bg-gray-100">
          <div className=" px-8 mt-2 rounded-lg w-full max-w-3xl mb-8">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="nome" className="block text-[#0d3960] mb-1">
                  Nome
                </label>
                <input
                  type="text"
                  id="nome"
                  name="nome"
                  value={formData.nome}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="cognome" className="block text-[#0d3960] mb-1">
                  Cognome
                </label>
                <input
                  type="text"
                  id="cognome"
                  name="cognome"
                  value={formData.cognome}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-[#0d3960] mb-1">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="telefono" className="block text-[#0d3960] mb-1">
                  Telefono
                </label>
                <input
                  type="tel"
                  id="telefono"
                  name="telefono"
                  value={formData.telefono}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="messaggio"
                  className="block text-[#0d3960] mb-1"
                >
                  Messaggio
                </label>
                <textarea
                  id="messaggio"
                  name="messaggio"
                  value={formData.messaggio}
                  onChange={handleChange}
                  className="w-full border bg-gray-100 border-[#0d3960] rounded-md py-2 px-4 focus:outline-none focus:border-blue-400"
                  rows="4"
                  required
                />
              </div>
              <div className="mb-4 flex items-center">
                <input
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  checked={formData.privacy}
                  onChange={handleChange}
                  className="mr-2 bg-transparent"
                  required
                />
                <label htmlFor="privacy" className="text-[#0d3960]">
                  Acconsento al trattamento dei dati personali
                </label>
              </div>
              <button
                type="submit"
                className="bg-[#0d3960] border border-[#0d3960] text-white py-2 px-6 rounded-md hover:bg-red-800 focus:outline-none focus:bg-red-800 w-full"
              >
                Invia
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FranchisingContact;
