import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWrench,
  faGem,
  faClock,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';
import heroImg from './hero.png';
import Contattaci from './Contattaci';
import ImageLabel from '../../editLabelComponents/imageLabel';
import TextLabel from '../../editLabelComponents/textLabel';
import { AuthContext } from '../../../App'; // Assicurati di importare AuthContext



import LaboratorioImg from '../../../img/orafo.png';

const GoldsmithLabService = () => {
  const { userType } = useContext(AuthContext);


  const handleLinkClick = (e) => {
    if (userType === 'superUser') {
      e.preventDefault();
      e.stopPropagation();
    }
  };
  return (
    <div className=" pt-[4.45rem] lg:pt-20 ">
    <div className="relative w-full h-auto lg:h-[30rem] xl:h-[40rem] flex flex-wrap items-center justify-center">
      <div className="absolute inset-0 w-full h-52 lg:h-full">
        <ImageLabel
          keyProp="orafo"
          customClass="w-full h-full object-cover"
          tagType="img"
        />
        <div className="absolute inset-0 lg:bg-black opacity-50 "></div>
      </div>
      <div className="relative z-10 text-[#0d3960] lg:text-white flex flex-wrap items-center justify-start w-full">
        <div className="container mt-10 lg:mt-20 mx-auto px-4 flex flex-col lg:flex-row justify-start items-left lg:items-start gap-8">
          <div className="lg:text-left text-left mt-48 lg:mt-10">
            <p className="mb-2 text-2xl">Servizio di</p>
            <h1 className="text-4xl lg:text-6xl mb-2">LABORATORIO ORAFO</h1>
            <hr className="border-t border-[#0d3960] lg:border-white w-16 mx-auto md:mx-0 my-2" />
          </div>
        </div>
      </div>
    </div>
    <header className="text-center mt-12">

<TextLabel
    keyProp="LABORATORIO_TITLE"
    tagType="h1"
    customClass="text-3xl font-bold text-[#0d3960] mt-2"
  />
<TextLabel
    keyProp="LABORATORIO_TITLE_P"
    tagType="p"
    customClass=" text-gray-700"
  />
</header>
    <div className="bg-white flex flex-col items-center justify-center p-8 py-8">
          <div className="w-full max-w-3xl">
            <div className="relative flex justify-center items-center">
              <div className="w-full  aspect-w-1 aspect-h-1">
                <iframe
                  src="https://www.youtube.com/embed/d0IjCbpRDi8?rel=0"
                  title="100 CARATI - Il Gioiello Ricondizionato"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-96"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="inline-block text-center rounded-lg border border-yellow-500 px-2 mt-8">
         

            {userType === 'superUser' ? (
              <a
              target="_blank"
              className="bg-gradient-to-r text-center from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300 text-2xl"
            >
              <TextLabel
                  keyProp="BOTTONE_RIGENERATI"
                  tagType="span"
                  customClass="text-yellow-500"
                />
            </a>
                ) : (
                  <a
              href="https://100carati.com/collections/gioielli-ricondizionati"
              target="_blank"
              className="bg-gradient-to-r text-center from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300 text-2xl"
            >
              <TextLabel
                  keyProp="BOTTONE_RIGENERATI"
                  tagType="span"
                  customClass="text-yellow-500"
                />
            </a>
                )}
          </div>
        </div>
      <div className="container bg-white mx-auto px-4 py-8">
       

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-yellow-500  rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faWrench}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
           <TextLabel
              keyProp="LABORATORIO_TITLE_A"
              tagType="h3"
              customClass="font-bold text-lg mb-2 text-[#0d3960]"
            />
                <TextLabel
              keyProp="LABORATORIO_PARAGRAPH_A"
              tagType="p"
              customClass="text-gray-700 -mt-3"
            />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faGem}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
              <TextLabel
              keyProp="LABORATORIO_TITLE_B"
              tagType="h3"
              customClass="font-bold text-lg mb-2 text-[#0d3960]"
            />
                <TextLabel
              keyProp="LABORATORIO_PARAGRAPH_B"
              tagType="p"
              customClass="text-gray-700 -mt-3"
            />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faClock}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
               <TextLabel
              keyProp="LABORATORIO_TITLE_C"
              tagType="h3"
              customClass="font-bold text-lg mb-2 text-[#0d3960]"
            />
                <TextLabel
              keyProp="LABORATORIO_PARAGRAPH_C"
              tagType="p"
              customClass="text-gray-700 -mt-3"
            />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
               <TextLabel
              keyProp="LABORATORIO_TITLE_D"
              tagType="h3"
              customClass="font-bold text-lg mb-2 text-[#0d3960]"
            />
                <TextLabel
              keyProp="LABORATORIO_PARAGRAPH_D"
              tagType="p"
              customClass="text-gray-700 -mt-3"
            />
          </div>
        </div>
      </div>
      <Contattaci />
    </div>
  );
};

export default GoldsmithLabService;
