import React, { useContext } from 'react';
import { AppContext } from './AppContext';
import { uploadImage } from '../../services/firebaseService';
import ImageActionButton from './actionButton/ImageActionButton';
import { AuthContext } from '../../App';

const ImageLabel = ({
  keyProp,
  customClass,
  styleProps,
  altText,
  tagType,
  backgroundImage,
}) => {
  const { images } = useContext(AppContext);
  const { isLoggedIn, userType } = useContext(AuthContext);
  const image = images.find((img) => img.key === keyProp);
  const Tag = tagType || 'img';
  const imageStyle =
    image && backgroundImage && Tag !== 'img'
      ? { ...styleProps, backgroundImage: `url(${image.url})` }
      : styleProps;

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadImage(keyProp, selectedFile);
    }
  };

  return (
    <>
      {image ? (
        <Tag
          src={Tag === 'img' ? image.url : undefined}
          alt={altText}
          className={customClass}
          style={imageStyle}
        />
      ) : (
        <p>No image available</p>
      )}
      {isLoggedIn && userType === 'superUser' && (
        <ImageActionButton onChange={handleFileChange} keyProp={keyProp} />
      )}
    </>
  );
};

export default ImageLabel;
