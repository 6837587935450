import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import TextLabel from '../../editLabelComponents/textLabel';

// Icona personalizzata per i marker
const customIcon = new L.Icon({
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

// Array di oggetti che contengono i dettagli degli indirizzi
const sedi = [
  {
    posizione: [41.4554929, 12.9057419],
    indirizzo: 'Latina via Isonzo 106',
    telefono: 'LATINA_VIA_ISONZO_TELEFONO',
    orario: 'LATINA_VIA_ISONZO_ORARIO',
    email: 'latina2@euroxoro.it',
    descrizione: 'LATINA_VIA_ISONZO_DESCRIZIONE',
  },
  {
    posizione: [41.4730616, 12.9078316],
    indirizzo: 'Latina via Ezio 2',
    telefono: 'LATINA_VIA_EZIO_TELEFONO',
    orario: 'LATINA_VIA_EZIO_ORARIO',
    email: 'latina3@euroxoro.it',
    descrizione: 'LATINA_VIA_EZIO_DESCRIZIONE',
  },
  {
    posizione: [41.5355302, 12.9478472],
    indirizzo: 'Latina scalo via della stazione 262',
    telefono: 'LATINA_SCALO_TELEFONO',
    orario: 'LATINA_SCALO_ORARIO',
    email: 'latinascalo@euroxoro.it',
    descrizione: 'LATINA_SCALO_DESCRIZIONE',
  },
  {
    posizione: [41.5925072, 12.6480624],
    indirizzo: 'Aprilia via degli Aranci 30/33 Angolo Largo delle Rose',
    telefono: 'APRILIA_ARANCI_TELEFONO',
    orario: 'APRILIA_ARANCI_ORARIO',
    email: 'aprilia@euroxoro.it',
    descrizione: 'APRILIA_ARANCI_DESCRIZIONE',
  },
  {
    posizione: [41.5894889, 12.6532134],
    indirizzo: 'Aprilia via Verdi 11',
    telefono: 'APRILIA_VIA_VERDI_TELEFONO',
    orario: 'APRILIA_VIA_VERDI_ORARIO',
    email: 'aprilia2@euroxoro.it',
    descrizione: 'APRILIA_VIA_VERDI_DESCRIZIONE',
  },
  {
    posizione: [41.4587928, 12.6669057],
    indirizzo: 'Nettuno via Visca 36',
    telefono: 'NETTUNO_VIA_VISCA_TELEFONO',
    orario: 'NETTUNO_VIA_VISCA_ORARIO',
    email: 'nettuno@euroxoro.it',
    descrizione: 'NETTUNO_VIA_VISCA_DESCRIZIONE',
  },
  // {
  //   posizione: [43.8968871, 12.9033713],
  //   indirizzo: 'PROSSIMA APERTURA: Pesaro via Giolitti 107',
  //   telefono: 'PESARO_VIA_GIOLITTI_TELEFONO',
  //   orario: 'PESARO_VIA_GIOLITTI_ORARIO',
  //   email: 'info@euroxoro.it',
  //   descrizione: 'PESARO_VIA_GIOLITTI_DESCRIZIONE',
  //   disabilitato: true,
  // },
  // {
  //   posizione: [43.9105027, 12.8953611],
  //   indirizzo: 'PROSSIMA APERTURA: Pesaro Strada Adriatica 67',
  //   telefono: 'PESARO_STRADA_ADRIATICA_TELEFONO',
  //   orario: 'PESARO_STRADA_ADRIATICA_ORARIO',
  //   email: 'marketing@euroxoro.it',
  //   descrizione: 'PESARO_STRADA_ADRIATICA_DESCRIZIONE',
  //   disabilitato: true,
  // },
];

const AccordionItem = ({ sede, index, isOpen, toggleAccordion }) => (
  <div
    className={`border border-gray-300 mb-2 shadow-lg rounded ${
      sede.disabilitato ? 'bg-gray-300' : ''
    }`}
  >
    <div
      className={`${
        sede.disabilitato ? 'bg-gray-500' : 'bg-[#0d3960]'
      } text-white p-4 cursor-pointer flex justify-between items-center ${
        isOpen ? 'rounded-t' : 'rounded'
      }`}
      onClick={() => !sede.disabilitato && toggleAccordion(index)}
    >
      <span>{sede.indirizzo}</span>
      <span
        className={`transform transition-transform ${
          isOpen ? 'rotate-180' : 'rotate-0'
        }`}
      >
        ▼
      </span>
    </div>
    {isOpen && !sede.disabilitato && (
      <div className="p-4 bg-[#0d3960] text-white rounded-b">
        <TextLabel keyProp={sede.telefono} tagType="p" customClass="-ml-2" />
        <hr className="my-2 border-gray-400" />
        <TextLabel keyProp={sede.orario} tagType="p" customClass="-ml-2" />

        <hr className="my-2 border-gray-400" />
        <p>
          Email:{' '}
          <a href={`mailto:${sede.email}`} className="text-blue-300 underline">
            {sede.email}
          </a>
        </p>
        {sede.descrizione && (
          <>
            <hr className="my-2 border-gray-400" />
            <TextLabel
              keyProp={sede.descrizione}
              tagType="p"
              customClass="-ml-2"
            />
          </>
        )}
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${sede.posizione[0]},${sede.posizione[1]}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 hover:text-white"
        >
          Apri in Google Maps
        </a>
      </div>
    )}
  </div>
);

const MappaSedi = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="map">
      <h2 className="text-2xl text-center font-bold text-[#0d3960] md:text-2xl mt-8">
        PUNTI VENDITA
      </h2>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 rounded-lg p-3 mt-5 order-2 md:order-1">
          <MapContainer
            center={[41.4643, 12.9056]}
            zoom={6}
            className="z-0 rounded-lg"
            style={{
              height: '390px',
              width: '100%',
            }}
            gestureHandling={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="">DM Project</a>'
            />
            {sedi.map((sede, index) => (
              <Marker key={index} position={sede.posizione} icon={customIcon}>
                <Popup>
                  <strong>{sede.indirizzo}</strong>
                  <br />
                  Tel: {sede.telefono}
                  <br />
                  Orario: {sede.orario}
                  <br />
                  Email: <a href={`mailto:{sede.email}`}>{sede.email}</a>
                  <br />
                  {sede.descrizione && <span>{sede.descrizione}</span>}
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${sede.posizione[0]},${sede.posizione[1]}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white underline"
                  >
                    Apri in Google Maps
                  </a>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>

        <div className="w-full md:w-1/2 p-3 mt-5 order-1 md:order-2">
          {sedi.map((sede, index) => (
            <AccordionItem
              key={index}
              sede={sede}
              index={index}
              isOpen={openIndex === index}
              toggleAccordion={toggleAccordion}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MappaSedi;
