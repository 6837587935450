import React from 'react';
import MappaSedi from './MapPuntiVendita';
import Contattaci from './Contattaci';

const locations = [
    "Aprilia 1 (LT)", 
    "Aprilia 2 (LT)",
    "Cattolica (RN)", 
    "Latina 1 (LT)", 
    "Latina 2 (LT)",
    "Latina 3 (LT)", 
    "Latina Scalo (LT)",
    "Nettuno (RM)", 
    "Pesaro 1 (PU)",
    "Pesaro 2 (PU)",
    "Torino (TO)",
    "Vigevano 1 (PV)", 
    "Vigevano 2 (PV)"
  ];

function PuntiVendita() {
  return (
    <>
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mt-14">
        <h1 className="text-3xl text-[#0d3960] font-bold mb-4">Scopri tutti i punti vendita EuroXOro</h1>
        <p className="text-lg text-[#0d3960]">Siamo in tutta Italia: trova il punto vendita più vicino a te!</p>
      </div>
      <MappaSedi/>
      <div className="text-center mt-8 mb-8 px-4">
        <p className="text-lg text-[#0d3960] mb-4">
          Le nostre sedi EUROXORO, sono garanzia di professionalità, trasparenza e cortesia.
        </p>
        <p className="text-lg text-[#0d3960] mb-4">
          Troverai professionisti del settore che con discrezione e competenza, saranno lieti di offrirti la migliore valutazione del momento, ed un pagamento immediato.
        </p>
        <p className="text-lg text-[#0d3960] mb-4">
          Hai anche la possibilità, solo telefonando alla sede più vicina, di bloccare il prezzo della tua valutazione.
        </p>
        <p className="text-lg text-[#0d3960]">
          Entra nei nostri negozi e scopri le differenze positive.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {locations.map((location, index) => (
          <div key={index} className="text-center rounded-lg border border-yellow-500 px-4 py-2">
            <a

              className="bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent  transition duration-300 text-xl"
            >
              {location}
            </a>
          </div>
        ))}
      </div>
    </div>
      <Contattaci/>
      </>
  )
}

export default PuntiVendita