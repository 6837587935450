import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faEnvelope, faFileInvoice } from '@fortawesome/free-solid-svg-icons';


function OperatorDashboard() {
  return (
    <div className="flex flex-col items-center p-4">
    <h1 className="text-2xl font-bold mb-4 mt-20">Pannello di Controllo Operatore</h1>
    
   

   

    <Link
      to="/vedicandidature"
      className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 mb-4 rounded flex items-center"
    >
      <FontAwesomeIcon icon={faUserCheck} className="mr-2" />
      Vedi Candidature
    </Link>

    <Link
      to="/vedimessaggi"
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center"
    >
      <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
      Vedi Messaggi
    </Link>
    <Link
      to="/admin/PrezziBloccatiOro"
      className="bg-yellow-500 hover:bg-red-700 text-white font-bold py-2 px-4 mt-4 rounded flex items-center"
    >
      <FontAwesomeIcon icon={faFileInvoice} className="mr-2" />
      Pannello Valutazioni
    </Link>


   
  </div>
  )
}

export default OperatorDashboard