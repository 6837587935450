// AppContext.js
import React, { createContext, useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { app } from '../../firebase';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [descriptions, setDescriptions] = useState([]);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchDescriptions = async () => {
            try {
                const db = getDatabase(app);
                const descriptionsRef = ref(db, 'description');
                onValue(descriptionsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const activeDescriptions = [];
                        for (const key in data) {
                            const texts = data[key].texts;
                            for (const textKey in texts) {
                                if (texts[textKey].active) {
                                    activeDescriptions.push({ key, ...texts[textKey] });
                                }
                            }
                        }
                        setDescriptions(activeDescriptions);
                    }
                });
            } catch (error) {
                console.error('Error fetching descriptions:', error);
            }
        };

        const fetchImages = async () => {
            try {
                const db = getDatabase(app);
                const imagesRef = ref(db, 'images');
                onValue(imagesRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const activeImages = [];
                        for (const key in data) {
                            const photos = data[key].photos;
                            for (const photosKey in photos) {
                                if (photos[photosKey].active) {
                                    activeImages.push({ key, ...photos[photosKey] });
                                }
                            }
                        }
                        setImages(activeImages);
                    }
                });
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchDescriptions();
        fetchImages();
    }, []);

    return (
        <AppContext.Provider value={{ descriptions, images }}>
            {children}
        </AppContext.Provider>
    );
};
