import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../App';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { app } from '../../../firebase';

const PdfPage = ({ pdfKey }) => {
  const { userType } = useContext(AuthContext);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [file, setFile] = useState(null);
  const storage = getStorage(app);

  const fetchPdfUrl = async () => {
    try {
      const url = await getDownloadURL(ref(storage, pdfKey));
      setPdfUrl(url);
    } catch (error) {
      console.error('Error fetching PDF URL', error);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      try {
        const storageRef = ref(storage, pdfKey);
        await uploadBytes(storageRef, file);
        fetchPdfUrl(); // Refresh the URL to the newly uploaded PDF
      } catch (error) {
        console.error('Error uploading PDF', error);
      }
    }
  };

  React.useEffect(() => {
    fetchPdfUrl();
  }, [pdfKey]);

  return (
    <div className="pdf-container" style={{ height: '100vh', width: '100vw' }}>
      {userType === 'superUser' && (
        <div className="mb-4">
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleUpload} className="btn btn-blue ml-2">
            Carica nuovo PDF
          </button>
        </div>
      )}
      {pdfUrl ? (
        <iframe src={pdfUrl} width="100%" height="100%" title="PDF Viewer" />
      ) : (
        <p>Caricamento...</p>
      )}
    </div>
  );
};

export default PdfPage;
