import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoins,
  faTags,
  faGift,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';
import Contattaci from './Contattaci';
import TextLabel from '../../editLabelComponents/textLabel';
import ImageLabel from '../../editLabelComponents/imageLabel';


import OroInvestimentoImg from '../../../img/oro_da_investimento.png';


const GoldInvestment = () => {
  return (
    <div className=" pt-[4.45rem] lg:pt-20 ">
      <div className="relative w-full h-auto lg:h-[30rem] xl:h-[40rem] flex flex-wrap items-center justify-center">
        <div className="absolute inset-0 w-full h-52 lg:h-full">
          <ImageLabel
            keyProp="oro_da_investimento"
            customClass="w-full h-full object-cover"
            tagType="img"
          />
          <div className="absolute inset-0 lg:bg-black opacity-50 "></div>
        </div>
        <div className="relative z-10 text-[#0d3960] lg:text-white flex flex-wrap items-center justify-start w-full">
          <div className="container mt-10 lg:mt-20 mx-auto px-4 flex flex-col lg:flex-row justify-start items-left lg:items-start gap-8">
            <div className="lg:text-left text-left mt-48 lg:mt-10">
              <p className="mb-2 text-2xl">Acquisto di</p>
              <h1 className="text-4xl lg:text-6xl mb-2">ORO DA INVESTIMENTO</h1>
              <hr className="border-t border-[#0d3960] lg:border-white w-16 mx-auto md:mx-0 my-2" />
            </div>
          </div>
        </div>
      </div>

      <div className="container bg-white mx-auto px-4 py-8">
        <header className="text-center mb-12">
       
        <TextLabel
            keyProp="INVESTIMENTO_TITLE"
            tagType="h1"
            customClass="text-3xl font-bold text-[#0d3960] "
          />
      
          <TextLabel
          keyProp="INVESTIMENTO_TITLE_P"
          tagType="P"
          customClass="text-gray-700"
        />
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faCoins}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
         
            <TextLabel
            keyProp="INVESTIMENTO_TITLE_A"
            tagType="h3"
            customClass="font-bold text-lg mb-2 text-[#0d3960] "
          />
            <TextLabel
            keyProp="INVESTIMENTO_PARAGRAPH_A"
            tagType="p"
            customClass="text-gray-700 -mt-3"
          />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faTags}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
             <TextLabel
            keyProp="INVESTIMENTO_TITLE_B"
            tagType="h3"
            customClass="font-bold text-lg mb-2 text-[#0d3960] "
          />
            <TextLabel
            keyProp="INVESTIMENTO_PARAGRAPH_B"
            tagType="p"
            customClass="text-gray-700 -mt-3"
          />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faGift}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
            <TextLabel
            keyProp="INVESTIMENTO_TITLE_C"
            tagType="h3"
            customClass="font-bold text-lg mb-2 text-[#0d3960] "
          />
            <TextLabel
            keyProp="INVESTIMENTO_PARAGRAPH_C"
            tagType="p"
            customClass="text-gray-700 -mt-3"
          />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
            <TextLabel
            keyProp="INVESTIMENTO_TITLE_D"
            tagType="h3"
            customClass="font-bold text-lg mb-2 text-[#0d3960] "
          />
            <TextLabel
            keyProp="INVESTIMENTO_PARAGRAPH_D"
            tagType="p"
            customClass="text-gray-700 -mt-3"
          />
          </div>
        </div>
      </div>
      <Contattaci/>
    </div>
  );
};

export default GoldInvestment;
