import React, { useState } from 'react';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, set, push } from 'firebase/database';
import { app } from '../../../firebase'; // Assicurati che l'istanza Firebase sia correttamente importata

const ArticleForm = () => {
  const [image, setImage] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [jobOffer, setJobOffer] = useState('No'); // Stato per gestire il nuovo campo

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload image to Firebase Storage
    const storage = getStorage(app);
    const imageRef = storageRef(storage, `images/${image.name}`);
    await uploadBytes(imageRef, image);

    // Get image URL from Firebase Storage
    const imageUrl = await getDownloadURL(imageRef);

    // Save article data to Firebase Realtime Database
    const db = getDatabase(app);
    const articlesRef = dbRef(db, 'blog'); // Assuming 'blog' is the path to articles in your database
    const newArticleRef = push(articlesRef);
    set(newArticleRef, {
      image: image.name,
      title: title,
      content: content,
      imageUrl: imageUrl,
      jobOffer: jobOffer // Includi il nuovo campo nei dati salvati
    });

    // Clear form fields
    setImage(null);
    setTitle('');
    setContent('');
    setJobOffer('No'); // Reset del nuovo campo
  };

  return (
    <div className="container mx-auto py-8 pt-20 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-[#0d3960] mb-6 text-center">Pubblica un nuovo articolo</h2>
      <form onSubmit={handleSubmit} className="max-w-xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <div className="mb-6">
          <label htmlFor="image" className="block text-lg font-medium text-[#0d3960] mb-2">Immagine:</label>
          <input 
            type="file" 
            id="image" 
            onChange={handleImageChange} 
            className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="title" className="block text-lg font-medium text-[#0d3960] mb-2">Titolo:</label>
          <input 
            type="text" 
            id="title" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)} 
            className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="content" className="block text-lg font-medium text-[#0d3960] mb-2">Contenuto:</label>
          <textarea 
            id="content" 
            value={content} 
            onChange={(e) => setContent(e.target.value)} 
            rows="6" 
            className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          ></textarea>
        </div>
        <div className="mb-6">
          <label htmlFor="jobOffer" className="block text-lg font-medium text-[#0d3960] mb-2">Offerta di lavoro:</label>
          <select 
            id="jobOffer" 
            value={jobOffer} 
            onChange={(e) => setJobOffer(e.target.value)} 
            className="w-full border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option value="No">No</option>
            <option value="Si">Si</option>
          </select>
        </div>
        <button 
          type="submit" 
          className="w-full bg-[#eab308] py-3 text-lg font-semibold text-white rounded-md shadow-md hover:bg-[#d1a007] focus:bg-[#d1a007] focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Pubblica
        </button>
      </form>
    </div>
  );
};

export default ArticleForm;
