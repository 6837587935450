import React, { useContext } from 'react';
import './Ring.css';
import ImageLabel from '../../editLabelComponents/imageLabel';
import Sostenibilita from '../../../img/sostenibilita.png';
import TextLabel from '../../editLabelComponents/textLabel';
import { AuthContext } from '../../../App'; // Assicurati di importare AuthContext


const SustainableJewelry = () => {
  const { userType } = useContext(AuthContext);

  return (
    <>
  <div className=" pt-[4.45rem] lg:pt-20 ">
    <div className="relative w-full h-auto lg:h-[30rem] xl:h-[40rem] flex flex-wrap items-center justify-center">
      <div className="absolute inset-0 w-full h-52 lg:h-full">
        <ImageLabel
          keyProp="sostenibilità"
          customClass="w-full h-full object-cover"
          tagType="img"
        />
        <div className="absolute inset-0 lg:bg-black opacity-50 "></div>
      </div>
      <div className="relative z-10 text-[#0d3960] lg:text-white flex flex-wrap items-center justify-start w-full">
        <div className="container mt-10 lg:mt-20 mx-auto px-4 flex flex-col lg:flex-row justify-start items-left lg:items-start gap-8">
          <div className="lg:text-left text-left mt-48 lg:mt-10">
            <h1 className="text-4xl lg:text-6xl mb-2">SOSTENIBILITÀ</h1>
            <hr className="border-t border-[#0d3960] lg:border-white w-16 mx-auto md:mx-0 my-2" />
          </div>
        </div>
      </div>
    </div>

        <div className="bg-white flex flex-col items-center justify-center p-8 py-8">
          <h2 className="text-2xl text-[#0d3960] font-bold text-center mb-4">SCOPRI IL NOSTRO METODO RELUX</h2>
          <div className="w-full max-w-3xl">
            <div className="relative flex justify-center items-center">
              <div className="w-full  aspect-w-1 aspect-h-1">
                <iframe
                  src="https://www.youtube.com/embed/d0IjCbpRDi8?rel=0"
                  title="100 CARATI - Il Gioiello Ricondizionato"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-96"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="inline-block text-center rounded-lg border border-yellow-500 px-2 mt-8">
          {userType === 'superUser' ? (
              <a
              target="_blank"
              className="bg-gradient-to-r text-center from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300 text-2xl"
            >
              <TextLabel
                  keyProp="BOTTONE_RIGENERATI"
                  tagType="span"
                  customClass="text-yellow-500"
                />
            </a>
                ) : (
                  <a
              href="https://100carati.com/collections/gioielli-ricondizionati"
              target="_blank"
              className="bg-gradient-to-r text-center from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300 text-2xl"
            >
              <TextLabel
                  keyProp="BOTTONE_RIGENERATI"
                  tagType="span"
                  customClass="text-yellow-500"
                />
            </a>
                )}
          </div>
        </div>

        <div className="flex flex-col items-center justify-center bg-gray-100 p-8 py-8">
          <TextLabel
                  keyProp="SOSTENIBILITA_TITLE_A"
                  tagType="h1"
                  customClass="text-3xl font-bold text-[#0d3960]"
                />
      
          <TextLabel
                  keyProp="SOSTENIBILITA_TITLE_P"
                  tagType="p"
                  customClass="text-center text-gray-700 mb-8"
                />
          <div className="relative flex flex-col sm:flex-row items-center justify-center">
            <div className="flex flex-col items-center justify-center sm:mr-12 mb-4 sm:mb-0">
              <TextLabel
                  keyProp="SOSTENIBILITA_P_A"
                  tagType="span"
                  customClass="text-gray-700 text-center sm:text-left"
                />
              <TextLabel
                  keyProp="SOSTENIBILITA_PP_A"
                  tagType="span"
                  customClass="text-[#0d3960] text-2xl font-bold"
                />
            </div>
            <div className="ring relative mb-4 sm:mb-0">
              <div className="absolute inset-0 flex items-center justify-center">

                <TextLabel
                  keyProp="SOSTENIBILITA_CERCHIO"
                  tagType="span"
                  customClass="text-[#0d3960] text-2xl font-bold text-center"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-center sm:ml-12">
            <TextLabel
                  keyProp="SOSTENIBILITA_P_B"
                  tagType="span"
                  customClass="text-gray-700 text-center sm:text-left"
                />
              <TextLabel
                  keyProp="SOSTENIBILITA_PP_B"
                  tagType="span"
                  customClass="text-[#0d3960] text-2xl font-bold"
                />
            </div>
          </div>
        
          <TextLabel
                  keyProp="SOSTENIBILITA_FINAL"
                  tagType="span"
                  customClass="text-center text-gray-700 mt-8"
                />
             
          
        </div>
      </div>
    </>
  );
};

export default SustainableJewelry;
