import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { app, database } from '../../../firebase'; // Adjust the import path as necessary
import { getDatabase, ref, get, push, set } from 'firebase/database';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';
import './ModalePreventivo.css';
import { AuthContext } from '../../../App'; // Assicurati di importare AuthContext


const SilverPriceCalculator = () => {
  const [carat, setCarat] = useState('999kt');
  const [grams, setGrams] = useState('');
  const [price, setPrice] = useState(null);
  const [pricePerGram, setPricePerGram] = useState({});
  const [currentPricePerGram, setCurrentPricePerGram] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const {  userType } = useContext(AuthContext); // Aggiornamento: importare userType
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    read: false,
  });
  const [userCode, setUserCode] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [caratDeduction, setCaratDeduction] = useState({
    '999kt': 0,
    '925kt': 0,
    '800kt': 0,
  });

  const caratConversion = {
    '999kt': 1.0,
    '925kt': 0.925,
    '800kt': 0.800,
  };

  const fetchSilverPricesFromAPI = async () => {
    try {
      const response = await axios.get(`https://api.metals.dev/v1/latest`, {
        params: {
          api_key: 'PHIECVFWPAJCSG1CSHVQ8981CSHVQ',
          currency: 'EUR',
          unit: 'g',
        },
      });

      const pricePerGram = response.data.metals.silver; // prezzo per grammo in EUR
      console.log('Prezzo dell\'argento per grammo dall\'API:', pricePerGram);

      const newPrices = {
        '999kt': pricePerGram,
        '925kt': pricePerGram * caratConversion['925kt'],
        '800kt': pricePerGram * caratConversion['800kt'],
      };

      const silverPricesRef = ref(database, 'silverPrices');
      await set(silverPricesRef, newPrices);

      console.log('Prezzi dell\'argento aggiornati nel database:', newPrices);
    } catch (error) {
      console.error('Error fetching silver prices:', error);
    }
  };

  const fetchSilverDeductionFromDB = async () => {
    const silverDeductionRef = ref(database, 'silverDeduction');
    try {
      const snapshot = await get(silverDeductionRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        // Convert fractions to percentages for display
        const deductionInPercent = {
          '999kt': data['999kt'] * 100,
          '925kt': data['925kt'] * 100,
          '800kt': data['800kt'] * 100,
        };
        return deductionInPercent;
      } else {
        console.error('No silver deduction data found in the database.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching silver deduction from database:', error);
      return null;
    }
  };

  const fetchSilverPricesFromDB = async () => {
    setLoading(true);
    setError(null);
  
    const silverPricesRef = ref(database, 'silverPrices');
    try {
      const snapshot = await get(silverPricesRef);
      if (snapshot.exists()) {
        const savedPrices = snapshot.val();
        
        const caratDeduction = await fetchSilverDeductionFromDB();
  
        if (caratDeduction) {
          const adjustedPrices = {
            '999kt': savedPrices['999kt'] * (1 - caratDeduction['999kt'] / 100),
            '925kt': savedPrices['925kt'] * (1 - caratDeduction['925kt'] / 100),
            '800kt': savedPrices['800kt'] * (1 - caratDeduction['800kt'] / 100),
          };
  
          setPricePerGram(adjustedPrices);
          setCurrentPricePerGram(adjustedPrices['999kt']);  // Imposta il prezzo corrente iniziale
          setCaratDeduction(caratDeduction);  // Aggiorna lo stato con le deduzioni recuperate
          setDataLoaded(true);
        } else {
          setError('Error fetching silver deduction');
        }
      } else {
        console.log('No data found in the database. Fetching from API...');
        await fetchSilverPricesFromAPI();
        await fetchSilverPricesFromDB();
      }
    } catch (error) {
      console.error('Error fetching silver prices from database:', error);
      setError('Error fetching silver prices');
    } finally {
      setLoading(false);
    }
  };

  const updateCaratDeductionInDB = async () => {
    const silverDeductionRef = ref(database, 'silverDeduction');
    try {
      const deductionInFraction = {
        '999kt': caratDeduction['999kt'] / 100,
        '925kt': caratDeduction['925kt'] / 100,
        '800kt': caratDeduction['800kt'] / 100,
      };
      await set(silverDeductionRef, deductionInFraction);
      console.log('Carat deduction updated in the database:', deductionInFraction);
      fetchSilverPricesFromDB(); // Update prices after changing deduction
    } catch (error) {
      console.error('Error updating carat deduction:', error);
    }
  };

  const handleCaratDeductionChange = (e) => {
    const { name, value } = e.target;
    setCaratDeduction((prevState) => ({
      ...prevState,
      [name]: parseFloat(value),
    }));
  };

  useEffect(() => {
    fetchSilverPricesFromDB();

    // Aggiorna i prezzi ogni 12 ore
    const interval = setInterval(() => {
      fetchSilverPricesFromAPI();
    }, 12 * 60 * 60 * 1000); // 12 ore in millisecondi

    // Pulisci l'intervallo quando il componente viene smontato
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (pricePerGram[carat]) {
      setCurrentPricePerGram(pricePerGram[carat]);
    }
  }, [carat, pricePerGram]);

  useEffect(() => {
    if (grams > 0 && currentPricePerGram) {
      const calculatedPrice = currentPricePerGram * grams;
      setPrice(calculatedPrice);
    } else {
      setPrice(null);
    }
  }, [grams, currentPricePerGram]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateUserCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const contactsRef = ref(database, 'ArgentoBloccato');
      const timestamp = Date.now();
      const generatedUserCode = generateUserCode(); // Generazione del codice utente univoco
      await push(contactsRef, {
        ...formData,
        price: price.toFixed(2),
        carat,
        grams,
        timestamp,
        userCode: generatedUserCode, // Inclusione del codice utente nel dato salvato
      });
      setUserCode(generatedUserCode);
      setFormData({ name: '', phone: '', email: '', message: '', read: false });
      setShowForm(false);
      setConfirmationModal(true); // Mostra il modal di conferma

      // Invia l'email di conferma
      const templateParams = {
        to_name: formData.name,
        to_email: formData.email,
        message: `La tua valutazione è stata presa in carico, il tuo codice utente è: ${generatedUserCode}. Il tuo argento vale € ${price.toFixed(2)} (${carat}) per un totale di € ${price.toFixed(2)}.`,
      };

      emailjs.send('service_a93zv2o', 'template_v15um5l', templateParams, '762ecfELT29WQJQxR')
        .then((response) => {
          console.log('Email inviata con successo!', response.status, response.text);
        })
        .catch((error) => {
          console.error('Errore nell\'invio dell\'email:', error);
        });

    } catch (error) {
      console.error("Errore durante l'invio del preventivo:", error);
      alert(
        "Si è verificato un errore durante l'invio del preventivo. Riprova più tardi."
      );
    }
  };

  useEffect(() => {
    if (price !== null) {
      setFormData((prevData) => ({
        ...prevData,
        message: `Devo vendere ${grams} Gr. di Argento, ho fissato il prezzo ad Euro ${price.toFixed(
          2
        )} (${carat}) per un totale di Euro ${price.toFixed(2)}`,
      }));
    }
  }, [price, carat, grams]);

  return (
    <div className="w-full mx-auto p-6 bg-[#ffffff63] rounded-lg shadow-md">
      <h2 className="text-2xl text-[#0d3960] font-bold mb-4">
        Valutazione Argento
      </h2>
  
      {error ? (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      ) : loading ? (
        <div className="text-center">Caricamento prezzi...</div>
      ) : (
        <>
          <div className="bg-[#0d3960] text-yellow-400 text-3xl font-bold p-4 text-center rounded-md">
            € {currentPricePerGram?.toFixed(2) || '...'} al grammo
          </div>
  
          <form className="space-y-4 mt-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Grammi:
                <div className="relative">
                  <input
                    type="number"
                    value={grams}
                    onChange={(e) =>
                      setGrams(e.target.value ? Number(e.target.value) : '')
                    }
                    required
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium text-[#0d3960]">
                Purezza:
                <select
                  value={carat}
                  onChange={(e) => setCarat(e.target.value)}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  {Object.keys(pricePerGram)
                    .filter((caratOption) => caratOption !== 'timestamp')
                    .map((caratOption) => (
                      <option key={caratOption} value={caratOption}>
                        {caratOption}
                      </option>
                    ))}
                </select>
              </label>
            </div>
          </form>
  
          {price !== null && (
            <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded-md">
              <h3 className="text-lg font-medium">
                Prezzo Totale: {price.toFixed(2)} €
              </h3>
              <button
                onClick={() => setShowForm(true)}
                className="mt-2 inline-block px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Blocca prezzo e richiedi un preventivo
              </button>
            </div>
          )}
  
          
          <Modal
            isOpen={showForm}
            onRequestClose={() => setShowForm(false)}
            contentLabel="Richiedi un preventivo"
            className="modal"
            overlayClassName="overlay"
          >
            <h2 className="text-2xl text-[#0d3960] font-bold mb-4">Richiedi un preventivo</h2>
            <div className="bg-[#0d3960] text-yellow-400 text-3xl font-bold p-4 text-center rounded-md">
              IL TUO ARGENTO VALE € {price ? price.toFixed(2) : '0.00'}
            </div>
            <form onSubmit={handleSubmit} className="space-y-4 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nome:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Telefono:
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  E-mail:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </label>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Quotazione:
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    readOnly
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  ></textarea>
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  name="read"
                  checked={formData.read}
                  onChange={(e) => setFormData({ ...formData, read: e.target.checked })}
                  required
                />
                <label className="ml-2 text-sm font-medium text-gray-700">
                  Accetto <a href="privacy" className="text-blue-500">Policy & Privacy</a>
                </label>
              </div>
              <button
                type="submit"
                className="mt-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Invia preventivo
              </button>
            </form>
          </Modal>
  
          <Modal
            isOpen={confirmationModal}
            onRequestClose={() => setConfirmationModal(false)}
            contentLabel="Conferma preventivo"
            className="modal"
            overlayClassName="overlay"
          >
            <h2 className="text-2xl text-[#0d3960] font-bold mb-4">Preventivo Inviato</h2>
            <div className="text-[#0d3960] text-lg p-4">
              La tua valutazione è stata presa in carico dai nostri operatori, riceverà a breve una mail di conferma con il seguente codice: <strong>{userCode}</strong>
              <br />
              Affrettati a raggiungerci in una delle nostre sedi, il codice utente è valido fino alle ore 19.00 della giornata odierna.
            </div>
            <button
              onClick={() => setConfirmationModal(false)}
              className="mt-4 px-4 py-2 bg-green-500 text-white font-semibold rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500"
            >
              Chiudi
            </button>
          </Modal>
          { userType === 'superUser' && (

          <div className="mt-4 p-4 bg-gray-100 border border-gray-400 rounded-md">
              <h3 className="text-lg font-medium">Modifica percentuale di sottrazione</h3>
              <form className="space-y-4 mt-4" onSubmit={(e) => { e.preventDefault(); updateCaratDeductionInDB(); }}>
                {Object.keys(caratDeduction).map((key) => (
                  <div key={key}>
                    <label className="block text-sm font-medium text-gray-700">
                      {key}:
                      <input
                        type="number"
                        step="0.01"
                        name={key}
                        value={caratDeduction[key]}
                        onChange={handleCaratDeductionChange}
                        required
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      />
                    </label>
                  </div>
                ))}
                <button
                  type="submit"
                  className="mt-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Aggiorna Percentuale
                </button>
              </form>
            </div>
            )}

        </>
      )}
    </div>
  );
};

export default SilverPriceCalculator;
