import React, { useEffect, useState } from 'react';
import { app, auth } from '../../../firebase';
import { getDatabase, ref, remove, update, onValue, get } from 'firebase/database';
import { deleteUser } from 'firebase/auth';

const UserListPage = () => {
  const [users, setUsers] = useState([]);
  const db = getDatabase(app);

  useEffect(() => {
    // Recupera la lista degli utenti dal database Firebase
    const usersRef = ref(db, 'operators');
    const unsubscribe = onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const userList = Object.keys(data).map(userId => ({
          id: userId,
          ...data[userId]
        }));
        setUsers(userList);
      } else {
        setUsers([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [db]);

  const handleDeleteUser = async (userId) => {
    try {
      // Get the user's auth record
      const userRef = ref(db, `operators/${userId}`);
      const snapshot = await get(userRef);
      const userData = snapshot.val();

      if (!userData) {
        throw new Error('User not found in the database');
      }



      // Remove the user from the database
      await remove(userRef);

      alert('Utente eliminato con successo!');
    } catch (error) {
      console.error('Errore durante l\'eliminazione dell\'utente:', error.message);
      alert('Si è verificato un errore durante l\'eliminazione dell\'utente. Controlla la console per i dettagli.');
    }
  };

  const handleChangeUserType = (userId, newUserType) => {
    // Modifica il userType dell'utente nel database Firebase
    const userRef = ref(db, `operators/${userId}`);
    update(userRef, { userType: newUserType });
  };

  const handleDisableUser = (userId, isDisabled) => {
    // Disabilita o abilita l'utente nel database Firebase
    const userRef = ref(db, `operators/${userId}`);
    update(userRef, { isDisabled });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl w-full space-y-8">
        <div>
          <h2 className="text-center text-3xl font-extrabold text-gray-900">Gestione Utenti</h2>
        </div>
        <div className="mt-8">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Tipo Utente</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Azioni</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.map((user) => (
                  <tr key={user.id}>
                    <td className="px-6 py-4 whitespace-nowrap">{user.username}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <select
                        value={user.userType}
                        onChange={(e) => handleChangeUserType(user.id, e.target.value)}
                        className="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="operatore">Operatore</option>
                        <option value="superUser">Super User</option>
                      </select>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleDisableUser(user.id, !user.isDisabled)}
                        className={`px-4 py-2 rounded-md text-white ${user.isDisabled ? 'bg-red-500 hover:bg-red-700' : 'bg-green-500 hover:bg-green-700'}`}
                      >
                        {user.isDisabled ? 'Abilita' : 'Disabilita'}
                      </button>
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="ml-2 px-4 py-2 bg-red-600 hover:bg-red-800 text-white rounded-md"
                      >
                        Elimina
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListPage;
