import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { EditContext } from "../EditContext";
import PreviewImageModal from '../PreviewImageModal';

import './upload.css';

const ImageActionButton = ({ onChange, keyProp }) => {
    const fileInputRef = useRef(null);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewSrc, setPreviewSrc] = useState(null);
    const { editingKey, setEditingKey } = useContext(EditContext);

    const handleUploadClick = () => {
        setEditingKey(keyProp);
        setIsEditing(true);
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value to allow the same file to be selected again
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewSrc(reader.result);
                document.body.style.overflow = 'hidden'; // Disable scrolling when modal is open
                setSelectedFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleConfirm = async () => {
        if (selectedFile) {
            await onChange({ target: { files: [selectedFile] } });
            setPreviewSrc(null);
            setSelectedFile(null);
            document.body.style.overflow = 'unset'; // Enable scrolling after modal is closed
        }
    };

    const handleClose = () => {
        setPreviewSrc(null);
        document.body.style.overflow = 'unset'; // Enable scrolling after modal is closed
    };

    useEffect(() => {
        if (editingKey !== keyProp) {
            setIsEditing(false);
        }
    }, [editingKey]);

    return (
        <>
            <div className="absolute inline-flex space-x-2" style={{ top: '50%'}}>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <button
                    onClick={handleUploadClick}
                    className="upload-button p-2 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 inline-flex "
                    style={{ width: '2.5rem', height: '2.5rem' }}
                >
                    <FontAwesomeIcon icon={faUpload} style={{ width: '1.5rem', height: '1.5rem' }} />
                </button>
            </div>
            {previewSrc && (
                <PreviewImageModal
                    imageSrc={previewSrc}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                />
            )}
        </>
    );
};

export default ImageActionButton;
