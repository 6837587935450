// TextLabel.js
import React, { useContext, useState, useRef, useEffect } from 'react';
import { AppContext } from './AppContext';
import { editText } from '../../services/firebaseService';
import { AuthContext } from '../../App';
import CustomTextarea from './customTextarea';
import TextActionButton from './actionButton/textActionButton';
import { EditContext } from './EditContext';
import HistoryModal from './historyModal';

const TextLabel = ({ keyProp, customClass, tagType, children }) => {
    const { descriptions } = useContext(AppContext);
    const { isLoggedIn, userType } = useContext(AuthContext); // Aggiornamento: importare userType
    const { editingKey, setEditingKey } = useContext(EditContext);
    const description = descriptions.find(desc => desc.key === keyProp);
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(description ? description.text : '');
    const inputRef = useRef(null);
    const spanRef = useRef(null);
    const [showHistory, setShowHistory] = useState(false);

    useEffect(() => {
        if (editingKey !== keyProp) {
            setIsEditing(false);
        }
    }, [editingKey]);

    if (!description) {
        return null;
    }

    const Tag = tagType;

    const handleEditClick = () => {
        if (editingKey && editingKey !== keyProp) {
            setEditingKey(null);
        }
        setEditingKey(keyProp);
        setIsEditing(true);
        setEditedText(description.text);
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }, 100);
    };

    const handleSaveClick = () => {
        if (editedText !== description.text) {
            editText(keyProp, editedText, true);
        }
        setIsEditing(false);
        setEditingKey(null);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedText(description.text);
        setEditingKey(null);
    };

    const handleOpenHistory = () => {
        setShowHistory(true);
        document.body.style.overflow = 'hidden';
    };

    const handleCloseHistory = () => {
        setShowHistory(false);
        document.body.style.overflow = 'unset';
    };
    
    return (
        <Tag className={`${customClass} relative`}>
            <div className="inline-flex items-center relative" style={{ padding: '0.5rem' }}>
                <span ref={spanRef} style={{ visibility: isEditing ? 'hidden' : 'visible' }}>
                    {isEditing ? editedText : description.text}
                </span>
                {isEditing && isLoggedIn && userType === 'superUser' && (
                    <CustomTextarea
                        value={editedText}
                        onChange={(e) => setEditedText(e.target.value)}
                        spanRef={spanRef}
                    />
                )}
                {isLoggedIn && userType === 'superUser' && (
                    <TextActionButton
                        isEditing={isEditing}
                        onEdit={handleEditClick}
                        onSave={handleSaveClick}
                        onCancel={handleCancelClick}
                        onHistory={handleOpenHistory}
                        isDisabled={editedText === description.text}
                    />
                )}
            </div>
            {children}
            {showHistory && <HistoryModal keyProp={keyProp} onClose={handleCloseHistory} />}
        </Tag>
    );
};

export default TextLabel;
