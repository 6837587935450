import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const Layout = ({ children }) => {
  const [isPdfOnly, setIsPdfOnly] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/privacy') || location.pathname.startsWith('/condizioni')) {
      setIsPdfOnly(true);
    } else {
      setIsPdfOnly(false);
    }
  }, [location]);

  return (
    <>
      {!isPdfOnly && <Navbar />}
      <div>{children}</div>
      {!isPdfOnly && <Footer />}
    </>
  );
};

export default Layout;
