import React, { useState, useEffect } from 'react';
import { getDatabase, ref, set } from 'firebase/database';

const CookieBanner = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Controlla se l'utente ha già accettato i cookie
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted === 'true') {
      setAcceptedCookies(true);
    } else {
      setShowPopup(true); // Mostra il popup se i cookie non sono stati accettati
    }
  }, []);

  const acceptCookies = () => {
    setAcceptedCookies(true);
    setShowPopup(false); // Nascondi il popup dopo aver accettato i cookie

    // Salva nello stato del localStorage che i cookie sono stati accettati
    localStorage.setItem('cookiesAccepted', 'true');

    // Ottieni l'ID dell'utente dal localStorage
    const userId = localStorage.getItem('userId');

    // Controlla se l'ID dell'utente è presente nel localStorage
    if (!userId) {
      console.error('User ID not found in localStorage');
      return;
    }

    // Ottieni un riferimento al database
    const db = getDatabase();

    // Imposta il valore "cookie:accepted" nel database per l'utente corrente
    set(ref(db, `users/${userId}/cookie`), 'accepted')
      .then(() => {
        console.log('Accettazione dei cookie registrata nel database per l\'utente', userId);
      })
      .catch((error) => {
        console.error('Errore durante il salvataggio dell\'accettazione dei cookie nel database:', error);
      });
  };

  if (acceptedCookies) {
    return null; // Nascondi il banner se i cookie sono stati accettati
  }

  return (
    <>
      {showPopup && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
            onClick={() => setShowPopup(false)}
          ></div>
          <div className="bg-white p-6 rounded-lg z-10">
            <p className="text-gray-800 mb-4">
              Questo sito utilizza i cookie per garantire una migliore esperienza utente. Continuando la navigazione, acconsenti al loro utilizzo.
            </p>
            <button
              onClick={acceptCookies}
              className="bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors duration-300"
            >
              Accetta
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
