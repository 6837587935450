import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import { app } from '../../../firebase'; // Assicurati che l'istanza Firebase sia correttamente importata

const ArticleDetail = () => {
  const { articleId } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch article data from Firebase Realtime Database
        const db = getDatabase(app);
        const articleRef = ref(db, `blog/${articleId}`);
        onValue(articleRef, async (snapshot) => {
          const data = snapshot.val();
          if (data) {
            // Fetch image from Firebase Storage
            const storage = getStorage(app);
            const imageUrl = await getDownloadURL(storageRef(storage, `images/${data.image}`));
            data.imageUrl = imageUrl;
            setArticle(data);
          }
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [articleId]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-10 py-8">

        <h1 className="text-3xl font-bold mt-20 text-[#0d3960] mb-4">{article.title}</h1>
        <div className="mb-6">
          <img src={article.imageUrl} alt={article.title} className="w-full h-64 object-cover rounded-lg" />
        </div>
        <div className="flex items-center mb-6">
          <div className="flex-shrink-0">
            <img src={article.authorImageUrl} alt={article.author} className="h-10 w-10 rounded-full" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-700">{article.author}</p>
            <p className="text-sm text-gray-500">{new Date(article.date).toLocaleDateString()}</p>
          </div>
        </div>
        <div className="prose max-w-none text-gray-700">
          <p>{article.content}</p>
        </div>
      </div>

  );
};

export default ArticleDetail;
