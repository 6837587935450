import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { getHistory, setTextFromHistory } from '../../services/firebaseService';

Modal.setAppElement('#root');

const HistoryPopUp = ({ keyProp, onClose }) => {
    const [history, setHistory] = useState([]);
    const [activeItem, setActiveItem] = useState(null);

    const fetchHistory = useCallback(async () => {
        const data = await getHistory(keyProp);
        const sortedData = data.sort((a, b) => new Date(b.lastUsageTimestamp) - new Date(a.lastUsageTimestamp));
        const active = sortedData.find(item => item.active === true);
        setActiveItem(active);
        setHistory(sortedData.filter(item => item.active !== true));
    }, [keyProp]);

    useEffect(() => {
        fetchHistory();
    }, [fetchHistory]);

    const handleItemClick = async (id) => {
        await setTextFromHistory(id, keyProp);
        await fetchHistory(); // Aggiorna la cronologia dopo aver aggiornato il testo
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
            className="relative bg-white rounded-lg shadow-lg p-6 w-full max-w-2xl mx-auto z-60"
        >
            <button onClick={onClose} className="absolute top-0 right-0 mt-4 mr-4 text-gray-700 text-2xl">
                &times;
            </button>
            <h2 className="text-2xl text-[#0d3960] font-bold mb-4" style={{ fontFamily: "'Poppins', sans-serif" }}>
                Cronologia della Key "{keyProp}"
            </h2>
            {activeItem && (
                <div className="p-2 border-b border-gray-200 bg-blue-100 flex justify-between items-center mb-4 rounded">
                    <div>
                        <p className="text-l text-blue-900 font-bold">{activeItem.text}</p>
                        <p className="text-xs text-gray-500">Data di creazione: {new Date(activeItem.timestamp).toLocaleString()}</p>
                    </div>
                    <span className="text-green-500 text-xl font-bold">&#10003;</span>
                </div>
            )}
            <div className="max-h-96 overflow-y-auto">
                {history.map((item, index) => (
                    <div
                        key={index}
                        className="p-2 border-b border-gray-200 cursor-pointer hover:bg-gray-100" // Aggiungi l'effetto hover
                        onClick={() => handleItemClick(item.id)}
                    >
                        <p className="text-l text-gray-900 font-bold">{item.text}</p>
                        <div className="flex justify-between text-xs text-gray-500">
                            <p>Data di creazione: {new Date(item.timestamp).toLocaleString()}</p>
                            <p>Ultimo utilizzo: {new Date(item.lastUsageTimestamp).toLocaleString()}</p>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default HistoryPopUp;
