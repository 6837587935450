import { useContext } from 'react';
import ValutazioneOro from './ValutazioneOro';
import ValutazioneArgento from './ValutazioneArgento';
import Hero from './Hero';
import About from '../contact/About';
import Servizi from './Servizi';
import MappaSedi from './SediMap';
import Blog from './Blog';
import Grid from './Grid';
import CookieBanner from '../../cookie/CookieBanner';

import Contattaci from './Contattaci';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';

import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebase'; // Adjust the import path as necessary
import { AuthContext } from '../../../App'; // Importa il contesto di autenticazione

import Testimonial from './Testimonial';

function Homepage() {
  const [user] = useAuthState(auth);
  const { userType } = useContext(AuthContext); // Ottieni userType dal contesto

  return (
    <div className="">
      <CookieBanner />

      <Hero />

      <Servizi />
      <Testimonial />

      <Blog />

      <MappaSedi />

      <Contattaci />

      {userType === 'superUser' && (
        <a
          className="fixed top-20 left-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-full shadow-lg flex items-center space-x-2"
          style={{ zIndex: 1000 }}
          href="/admin"
        >
          <FontAwesomeIcon className="mr-1" icon={faNewspaper} />
          Pannello di Controllo
        </a>
      )}

      {userType === 'operatore' && (
        <a
          className="fixed top-20 left-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-full shadow-lg flex items-center space-x-2"
          style={{ zIndex: 1000 }}
          href="/dashboardoperatore"
        >
          <FontAwesomeIcon className="mr-1" icon={faNewspaper} />
          Pannello di Controllo Operatore
        </a>
      )}
    </div>
  );
}

export default Homepage;
