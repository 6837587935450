import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

// Icona personalizzata per i marker
const customIcon = new L.Icon({
  iconUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

// Array di oggetti che contengono i dettagli degli indirizzi
const sedi = [
  {
    posizione: [41.4643, 12.9056],
    indirizzo: 'Latina via Isonzo 106',
    telefono: '0773487512',
    orario: '09:00/13:00 - 15:30/19:00',
    email: 'latina2@euroxoro.it',
    descrizione: 'Oltre attività di compro oro è anche outlet del gioiello',
  },
  {
    posizione: [41.4662, 12.8925],
    indirizzo: 'Latina via Ezio 2',
    telefono: '07731761052',
    orario: '08:30/13:00 - 16:00/19:30',
    email: 'latina3@euroxoro.it',
  },
  {
    posizione: [41.5136, 12.8532],
    indirizzo: 'Latina scalo via della stazione 262',
    telefono: '0773631149',
    orario: '08:30/13:00 - 16:00/19:30',
    email: 'latinascalo@euroxoro.it',
  },
  {
    posizione: [41.5888, 12.6499],
    indirizzo: 'Aprilia via degli Aranci 30/33 Angolo Largo delle Rose',
    telefono: '0692708263',
    orario: '08:30/13:00 - 16:00/19:30',
    email: 'aprilia@euroxoro.it',
  },
  {
    posizione: [41.5947, 12.6573],
    indirizzo: 'Aprilia via Verdi 11',
    telefono: '0692730458',
    orario: '09:00/13:00 - 14:00/18:00',
    email: 'aprilia2@euroxoro.it',
  },
  {
    posizione: [41.4586, 12.6635],
    indirizzo: 'Nettuno via Visca 36',
    telefono: '069854836',
    orario: '08:30/13:00 - 16:00/19:30',
    email: 'nettuno@euroxoro.it',
    descrizione: 'Oltre attività di compro oro è anche outlet del gioiello',
  },
  {
    posizione: [43.9097, 12.9135],
    indirizzo: 'Pesaro via Giolitti 107',
    telefono: '07211415308',
    orario: '09:00/12:30 - 15:30/19:00',
    email: 'info@euroxoro.it',
  },
  {
    posizione: [43.9201, 12.8934],
    indirizzo: 'Pesaro Strada Adriatica 67',
    telefono: '0721405037',
    orario: '09:00/12:30 - 15:30/19:00',
    email: 'marketing@euroxoro.it',
  },
];

const AccordionItem = ({ sede, index, isOpen, toggleAccordion }) => (
  <div className="border border-gray-300 mb-2 shadow-lg rounded">
    <div
      className={`bg-[#0d3960] text-white p-4 cursor-pointer flex justify-between items-center ${
        isOpen ? 'rounded-t' : 'rounded'
      }`}
      onClick={() => toggleAccordion(index)}
    >
      <span>{sede.indirizzo}</span>
      <span
        className={`transform transition-transform ${
          isOpen ? 'rotate-180' : 'rotate-0'
        }`}
      >
        ▼
      </span>
    </div>
    {isOpen && (
      <div className="p-4 bg-[#0d3960] text-white rounded-b">
        <p>Tel: {sede.telefono}</p>
        <hr className="my-2 border-gray-400" />
        <p>Orario: {sede.orario}</p>
        <hr className="my-2 border-gray-400" />
        <p>
          Email:{' '}
          <a href={`mailto:${sede.email}`} className="text-blue-300 underline">
            {sede.email}
          </a>
        </p>
        {sede.descrizione && (
          <>
            <hr className="my-2 border-gray-400" />
            <p>{sede.descrizione}</p>
          </>
        )}
      </div>
    )}
  </div>
);

const MappaSedi = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section id="map">
    <div className="flex flex-col md:flex-row z-10">
      <div className="w-full md:w-1/2 rounded-lg p-3 order-2 md:order-1">
        <MapContainer
          center={[42.3, 11.9056]}
          zoom={6}
          className="z-0 rounded-lg h-[17.5rem] w-[23rem] lg:w-[40rem] "
        //   style={{
        //     height: '18rem',
        //     width: '100%',
        //     maxWidth: '45rem',
        //   }}
          gestureHandling={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="">DM Project</a>'
          />
          {sedi.map((sede, index) => (
            <Marker key={index} position={sede.posizione} icon={customIcon}>
              <Popup>
                <strong>{sede.indirizzo}</strong>
                <br />
                Tel: {sede.telefono}
                <br />
                Orario: {sede.orario}
                <br />
                Email: <a href={`mailto:{sede.email}`}>{sede.email}</a>
                <br />
                {sede.descrizione && <span>{sede.descrizione}</span>}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  </section>
  );
};

export default MappaSedi;
