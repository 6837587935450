import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStore,
  faUserTie,
  faClipboardCheck,
  faMoneyBillWave,
  faBalanceScale,
} from '@fortawesome/free-solid-svg-icons';
import Contattaci from './Contattaci';
import ComproOroImg from '../../../img/compro_oro.png';

import TextLabel from '../../editLabelComponents/textLabel';

import ValutazioneOro from './ValutazioneOro';
import ValutazioneArgento from './ValutazioneArgento';

import './ModalePreventivo.css';

import ImageLabel from '../../editLabelComponents/imageLabel';


import heroImg from './hero.png';

const MarketQuotes = () => {
  return (
    <div className=" pt-[4.45rem] lg:pt-20 ">
      <div className="relative w-full h-auto lg:h-[30rem] xl:h-[40rem] 2xl:[50rem] 3xl:[60rem] flex flex-wrap items-center justify-center">
        <div className="absolute inset-0 w-full h-52 lg:h-full">
          <ImageLabel
            keyProp="compro_oro"
            customClass="w-full h-full object-cover"
            tagType="img"
          />
          <div className="absolute inset-0 lg:bg-black opacity-50 "></div>
        </div>
        <div className="relative z-10 text-[#0d3960] lg:text-white flex flex-wrap items-center justify-start w-full">
          <div className="container mt-10 lg:mt-20 mx-auto px-4 flex flex-col lg:flex-row justify-start items-left lg:items-start gap-8">
            <div className="lg:text-left text-left mt-48 lg:mt-10">
              <p className="mb-2 text-2xl">Servizio di</p>
              <h1 className="text-4xl lg:text-6xl mb-2">COMPRO ORO</h1>
              <hr className="border-t border-[#0d3960] lg:border-white w-16 mx-auto md:mx-0 my-2" />
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 bg-white">
        <header className="text-center mb-12">
          {/* <h2 className="text-[#0d3960] font-semibold text-sm">
            SERVIZIO DI COMPRO ORO
          </h2> */}
          <TextLabel
            keyProp="COMPROORO_TITLE"
            tagType="h1"
            customClass="text-3xl font-bold text-[#0d3960] -mb-7"
          />
        </header>

        <TextLabel
          keyProp="COMPROORO_TITLE_P"
          tagType="h1"
          customClass="text-center mb-12 text-gray-700"
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faStore}
              className="text-yellow-500 h-12 w-12 mb-4"
            />

            <TextLabel
              keyProp="COMPROORO_TITLE_A"
              tagType="h3"
              customClass="font-bold text-[#0d3960] text-lg mb-2"
            />
            <TextLabel
                  keyProp="COMPROORO_PARAGRAPH_A"
                  tagType="p"
                  customClass="text-gray-700"
                />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faClipboardCheck}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
            <TextLabel
              keyProp="COMPROORO_TITLE_B"
              tagType="h3"
              customClass="font-bold text-[#0d3960] text-lg mb-2"
            />
            <TextLabel
                  keyProp="COMPROORO_PARAGRAPH_B"
                  tagType="p"
                  customClass="text-gray-700"
                />
          </div>

          <div className="border border-yellow-500 rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faUserTie}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
            <TextLabel
              keyProp="COMPROORO_TITLE_C"
              tagType="h3"
              customClass="font-bold text-[#0d3960] text-lg mb-2"
            />
             <TextLabel
                  keyProp="COMPROORO_PARAGRAPH_C"
                  tagType="p"
                  customClass="text-gray-700"
                />
          </div>

          <div className="border  border-yellow-500  rounded-lg p-6 text-center">
            <FontAwesomeIcon
              icon={faMoneyBillWave}
              className="text-yellow-500 h-12 w-12 mb-4"
            />
            <TextLabel
              keyProp="COMPROORO_TITLE_D"
              tagType="h3"
              customClass="font-bold text-[#0d3960] text-lg mb-2"
            />
             <TextLabel
                  keyProp="COMPROORO_PARAGRAPH_D"
                  tagType="p"
                  customClass="text-gray-700"
                />
          </div>
        </div>
        <div className="group mt-8 relative grid grid-cols-1 md:grid-cols-3 gap-4 bg-transparent border border-yellow-500 rounded-lg p-4">
          <div className="flex flex-col justify-center items-center text-center space-y-4">
            <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gradient-to-tl from-[#c5a66a] via-[#e7c580] to-[#c5a66a]">
              <FontAwesomeIcon
                icon={faBalanceScale}
                className="w-8 h-8 text-white"
              />
            </div>
            <div className="space-y-2">
              <TextLabel
                keyProp="SERVIZI_A_TITLE"
                tagType="h5"
                customClass="text-xl font-semibold text-[#0d3960] transition"
              />
              <TextLabel
                keyProp="SERVIZI_A_PARAGRAFO"
                tagType="p"
                customClass="text-gray-400"
              />
              <div className="inline-block text-center rounded-lg border border-yellow-500 px-2">
                <a
                  href="/sedi"
                  className="bg-gradient-to-r from-yellow-600 via-yellow-500 to-yellow-600 bg-clip-text text-transparent hover:text-yellow-300 transition duration-300"
                >
                  Scopri le nostre sedi
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 flex flex-col items-center">
            <ValutazioneOro className="h-full" />
          </div>
          <div className="rounded-lg p-4 flex flex-col items-center">
            <ValutazioneArgento className="h-full" />
          </div>
        </div>
      </div>
      <Contattaci />
    </div>
  );
};

export default MarketQuotes;
