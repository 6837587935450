import { getDatabase, ref, set, push, update, onValue } from "firebase/database";
import {getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject, listAll} from "firebase/storage";

import { v4 as uuidv4 } from "uuid";
import { app } from "../firebase";

export function editText(key, text, active) {
    const db = getDatabase(app);
    const refKey = ref(db, `description/${key}`);
    const id = uuidv4();
    const newEntry = {
        id: id,
        timestamp: Date.now(),
        text: text,
        active: active
    };

    onValue(refKey, (snapshot) => {
        const data = snapshot.val();
        if (data) {
            const textsRef = ref(db, `description/${key}/texts`);
            let textExists = false;
            const updates = {};

            // Controlla se il text esiste già
            Object.keys(data.texts).forEach(textKey => {
                if (data.texts[textKey].text === text) {
                    textExists = true;
                    if (!data.texts[textKey].active) {
                        // Se il text esiste ma è disattivo, rendilo attivo
                        updates[`texts/${textKey}/active`] = true;
                        updates[`texts/${textKey}/lastUsageTimestamp`] = Date.now();
                    }
                } else {
                    // Disattiva tutti i text esistenti
                    updates[`texts/${textKey}/active`] = false;
                }
            });

            if (!textExists) {
                // Aggiungi il nuovo text
                const newTextKey = push(textsRef).key;
                newEntry.lastUsageTimestamp = Date.now();
                updates[`texts/${newTextKey}`] = newEntry;
            }

            // Esegui gli aggiornamenti
            update(refKey, updates).then(() => {
                console.log("Text aggiornato correttamente!");
            }).catch((error) => {
                console.log("Errore nell'aggiornare il text:", error);
            });
        } else {
            // La chiave non esiste, crea una nuova entry con il primo text
            newEntry.lastUsageTimestamp = Date.now();
            const newData = {
                texts: {
                    [id]: newEntry
                }
            };
            set(refKey, newData).then(() => {
                console.log("Dati scritti correttamente!");
            }).catch((error) => {
                console.log("Errore nella scrittura dei dati:", error);
            });
        }
    }, {
        onlyOnce: true
    });
}


export const getHistory = async (keyProp) => {
    try {
        const db = getDatabase(app);
        const historyRef = ref(db, `description/${keyProp}/texts`);
        return new Promise((resolve, reject) => {
            onValue(historyRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    const historyItems = Object.values(data).sort((a, b) => b.timestamp - a.timestamp);
                    resolve(historyItems);
                } else {
                    resolve([]);
                }
            }, (error) => {
                reject(error);
            });
        });
    } catch (error) {
        console.error('Error fetching history:', error);
        return [];
    }
};

export function setTextFromHistory(id, key) {
    const db = getDatabase(app);
    const refKey = ref(db, `description/${key}`);
    const updates = {};

    onValue(refKey, (snapshot) => {
        const data = snapshot.val();
        if (data && data.texts) {
            let textFound = false;

            // Cerca il testo con l'ID fornito e disattiva tutti gli altri
            Object.keys(data.texts).forEach(textKey => {
                if (data.texts[textKey].id === id) {
                    textFound = true;
                    updates[`texts/${textKey}/active`] = true;
                    updates[`texts/${textKey}/lastUsageTimestamp`] = Date.now();
                } else {
                    updates[`texts/${textKey}/active`] = false;
                }
            });

            if (textFound) {
                // Esegui gli aggiornamenti se il testo con l'ID fornito è stato trovato
                update(refKey, updates).then(() => {
                    console.log("Text aggiornato correttamente!");
                }).catch((error) => {
                    console.log("Errore nell'aggiornare il text:", error);
                });
            } else {
                console.log("ID del text non trovato.");
            }
        } else {
            console.log("Chiave non trovata o nessun testo esistente.");
        }
    }, {
        onlyOnce: true
    });
}

export async function uploadImage(key, file, deleteOthers = true) {
    const storage = getStorage(app);
    const db = getDatabase(app);
    const newImageId = uuidv4();
    const fileRef = storageRef(storage, `images/${key}/${newImageId}`);

    try {
        // Upload the file to Firebase Storage
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);

        // Prepare the new image data
        const newImageData = {
            id: newImageId,
            url: url,
            timestamp: Date.now(),
            active: true,
            lastUsageTimestamp: Date.now()
        };

        // Reference to the images path in the Realtime Database
        const imagesRef = ref(db, `images/${key}/photos/`);

        // Get current images and update their active status
        onValue(imagesRef, async (snapshot) => {
            const data = snapshot.val();
            const updates = {};

            if (data) {
                Object.keys(data).forEach(imageKey => {
                    updates[`${imageKey}/active`] = false;
                });

                // Delete other images if deleteOthers is true
                if (deleteOthers) {
                    const folderRef = storageRef(storage, `images/${key}`);
                    const listResult = await listAll(folderRef);
                    const deletePromises = listResult.items.map((itemRef) => {
                        if (itemRef.name !== newImageId) {
                            return deleteObject(itemRef);
                        }
                        return null;
                    });
                    await Promise.all(deletePromises.filter(promise => promise !== null));
                }
            }

            // Add the new image data
            updates[newImageId] = newImageData;

            // Execute updates
            update(imagesRef, updates).then(() => {
                console.log("Image uploaded and saved successfully!");
            }).catch((error) => {
                console.error("Error updating images:", error);
            });
        }, {
            onlyOnce: true
        });
    } catch (error) {
        console.error("Error uploading image:", error);
    }
}